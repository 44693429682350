<!-- HISTORY:
- V240604.1: Fixed the logic of headersSeq as it didn't work properly.
- V240529.1: Added sortColumn and sortOrder props and related logic if provided.
- V240401.1: Ignored total columns with error instead of showing in red.
- V240325.2: Fixed totalListCount calculation + Managed to display totals only if was a valid non-zero number +
   Managed to show error columns in red.
- V240321.1: Changed the logic of calculating Total columns by resolving the calculated column expressions.
- V240320.1: Supported Total column for the numeric calculated column as well (only columns with %, $ or no pre/postfix).
- V230712.1: Added reportId prop to support a listCount variable in the calculatedColumns (specific to account-groupby-event-list RDL) +
   Replaced hasOwnProperty() with hasOwn() in mixins.
- V230202.1: Commented the alert in cellClicked().
- 09/02/22(B0.11): In init(), changed the logic to handle extra columns in eventsSequence by sorting.
- 09/01/22(B0.10): In init(), returned  back if tableHeaders has no items in it.
- 08/26/22(B0.9): Added headersAlignment prop and consumed it in getHeader() + Added customHeader prop for the first table header +
   Added a total row to the end of table & ability to use the totals in calculations by using $$ in front of the field name.
- 01/26/21(B0.8): Moved the class from inside <template>.<div> to <td> to resolve the overlapping issue in Firefox (Firefox has issue with the style clause).
- 10/18/21(B0.7): Removed zeros, non-numeric and infinity values from displaying + Removed commented codes.
- 10/13/21(B0.6): Changed the code to handle formula with eval instead of percent and sum + Changed header.align from 'end' to 'start'.
- 10/05/21(B0.5): Changed position values and added before/after column options.
- 10/05/21(B0.4): Fixed bug with large percents + Changed highlight color + Added borders + Added position. 
- 10/05/21(B0.3): Kept the click position to handle re/un-click + Applied formatting to the table. 
- 10/01/21(B0.2): Added colDim and rowDim props, and click event.
- 09/30/21(B0.1): 1st release.
-->
<!--
   font-weight-black, font-weight-bold, font-italic
   text-left, text-center, text-right
   text-decoration-none, text-decoration-underline
   text--primary, text--secondary, text--disabled
-->
<template>
   <v-data-table dense :hide-default-header="false"
      class="elevation-1 mb-2"
      :footer-props="footerProps"
      :headers="tableHeaders"
      :hide-default-footer="tableData.length <= footerProps.itemsPerPageOptions[0]"
      item-key="id"
      :items="tableData"
      :items-per-page="itemsPerPage"
      :loading="loadingTableData"
      loading-text="Loading data. Please wait..."
      no-data-text="No data are available."
      no-results-text="No matching data were found."
   >
      <template v-slot:body="{ items }">
         <tbody>
            <tr v-for="(item, i) in items" :key="i">
               <td v-for="(header, j) in tableHeaders" :key="header.value"
                  :class="`px-0 ${calculatedColumns.find(cc => cc.name === header.value) ? backgroundClass : ''}`"
               >
                  <!-- <div
                     style="height:98%;"
                     :class="`px-0 ${calculatedColumns.find(cc => cc.name === header.value) ? backgroundClass : ''}`"
                  > -->
                     <div v-if="j === 0"
                        class="px-2 d-flex black--text"
                     >{{ item[header.value] }}</div>
                     <div v-else-if="!item[header.value] || isNaN(item[header.value]) || !Number.isFinite(item[header.value])"></div>
                     <div v-else-if="calculatedColumns.find(cc => cc.name === header.value)"
                        class="px-2 d-flex justify-end black--text"
                     >{{ formatValue(item[header.value], header.value) }}</div>
                     <a v-else
                        style="text-decoration: none;"
                        :class="`px-2 d-flex justify-end ${i != lastClickedRowInd || j != lastClickedColInd ? 'black--text' : 'blue--text font-italic'}`"
                        href="#"
                        @click="cellClicked(item[tableHeaders[0].value], header.value, i, j)"
                     >{{ formatValue(item[header.value], header.value) }}</a>
                  <!-- </div> -->
               </td>
            </tr>
            <tr class="blue-grey lighten-4">
               <td class="px-2">Total:</td>
               <td v-for="(header, i) in tableHeaders.slice(1)" :key="i" class="px-0 py-3">
                  <div v-if="Object.prototype.hasOwnProperty.call(header, 'TotalCol_' + header.value)">
                     <span v-if="Number(header['TotalCol_' + header.value])"
                        class="font-weight-bold px-2 d-flex justify-end"
                     >{{formatValue(header['TotalCol_' + header.value], header.value)}}</span>
                     <!-- <span v-else
                        class="red--text px-2 d-flex justify-end"
                     >{{header['TotalCol_' + header.value]}}</span> -->
                  </div>
               </td>
            </tr>
         </tbody>
      </template>
   </v-data-table>
</template>

<script>
import { hasOwn } from '../mixins/bt-mixin.js';

const NAME = "BtCalculatedTable";
const MSG = `-----${NAME} V240604.1 says => `;

export default {
   name: NAME,

   props: {
      calculatedColumns: {
         //Old version: [{operator:'%', name: '', position: 's::|e::|b::name|a::name', headers:[divident, divisor] }]
         //New version: [{name:'',expression:'1 or more {{}}',fix:'none::|pre::text|post::text',position:'s::|e::|b::name|a::name'}
         type: Array,
         default: () => []
      },
      canEditChart: {
         type: Boolean,
         default: false
      },
      chartData: {
         type: Array,
         required: true,
         default: () => []
      },
      colDim: {
         type: String,
         required: true,
         default: 'colDIM'
      },
      customHeader: {
         type: String
      },
      debug: {
         type: Boolean,
         default: false
      },
      footerProps: {
         type: Object,
         default: () => { 
            return {
               itemsPerPageOptions: [5, 10, 20],
               showFirstLastPage: true
            }
         }
      },
      headersAlignment: {
         type: String,
         default: 'start'
      },
      headersSeq: {
         type: Array,
         default: () => []
      },
      itemsPerPage: {
         type: Number,
         default: 5
      },
      options: {
         type: Object,
         default: () => {
            return {
               "class":"elevation-1 mt-2 mb-2 font-weight-light caption",
               // "class":"font-weight-bold body-2 font-italic"
               "align": "right"
            }
         }
      },
      reportId: {
         type: String
      },
      rowDim: {
         type: String,
         required: true,
         default: 'rowDIM'
      },
      sortColumn: {
         type: String
      },
      sortOrder: {
         type: String
      }
   },

   data() {
      return {
         loadingTableData: true,
         backgroundClass: 'grey lighten-3',
         lastClickedRowInd: -1,
         lastClickedColInd: -1,
         // myHeadersSeq: null
      }
   },

   computed: { },

   watch: {
      chartData: {
         immediate: false,
         deep: true,
         handler() {
            this.init();
         }
      }
   },

   methods: {
      log(msg, isError) {
         if (isError)
            console.error(`${MSG}${msg}`);
         else if (this.debug) {
            console.log(`${MSG}${msg}`);
            // alert(`${MSG}${msg}`);
         }
      },

      formatValue(val, header) {
         let result;
         // if (isNaN(val))
         //    result = val;
         // else {
            result = new Intl.NumberFormat().format(val);
            const calCol = this.calculatedColumns.find(cc => cc.name === header);
            if (calCol) {
               const fixParts = calCol.fix.split('::');
               if (fixParts[1] === '%') {
                  result = new Intl.NumberFormat('en-US',
                     {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                     }
                  ).format(val);
               }
               
               if (fixParts[0] === 'pre')
                  result = fixParts[1] + result;
               else if (fixParts[0] === 'post')
                  result += fixParts[1];
            }
         // }
         // console.log('in formatValue: result=' + result);
         return result;
      },

      init() {
         // alert('init() started: chartData=' + JSON.stringify(this.chartData));
         this.tableHeaders = [];
         this.tableData = [];
         if (!this.chartData || !this.chartData.length || !this.chartData[0].length)
            return;

         this.loadingTableData = true;

         this.chartData[0].forEach(h => {
            if (this.customHeader && this.tableHeaders.length === 0)
               this.tableHeaders.push(this.getHeader(this.customHeader, h.id));
            else 
               this.tableHeaders.push(this.getHeader(h.label, h.id));
         });

         // alert(`in init(): tableHeaders=${JSON.stringify(this.tableHeaders)}`);

         if (!this.tableHeaders.length) {
            console.error(`in ${NAME}.init(): Ending method because tableHeaders is empty!`);
            return;
         }

         this.tableHeaders[0].seq = 0;

         // alert('headersSeq=' + JSON.stringify(this.headersSeq));

         /* V240604 - start */
         // if (this.headersSeq && this.headersSeq.length > 0) {
         //    for (let index = 1; index < this.tableHeaders.length; index++) {
         //       const element = this.tableHeaders[index];
         //       // using '==' instead of '===' because value types are different.
         //       const seq = this.headersSeq.findIndex(hs => hs == element.value);
         //       // alert('element=' + JSON.stringify(element) + '\nseq=' + seq);
         //       if (seq > -1)
         //          element.seq = seq + 1;
         //    }
         //    // alert('tableHeaders with seq=' + JSON.stringify(this.tableHeaders));
         // } else {
         //    for (let index = 1; index < this.tableHeaders.length; index++) {
         //       this.tableHeaders[index].seq = index;
         //    }
         // }

         const hsLen = this.headersSeq ? this.headersSeq.length : 0;
         for (let index = 1; index < this.tableHeaders.length; index++) {
            const element = this.tableHeaders[index];
            // using '==' instead of '===' because value types are different.
            const seq = this.headersSeq.findIndex(hs => hs == element.value);
            // alert('element=' + JSON.stringify(element) + '\nseq=' + seq);
            element.seq = seq > -1 ? seq + 1 : index + hsLen;
         }
         /* V240604 - end */

         // alert('tableHeaders with seq=' + JSON.stringify(this.tableHeaders));

         for (let i = 1; i < this.chartData.length; i++) {
            const d = this.chartData[i];
            const item = {};
            item[this.tableHeaders[0].value] = d[0].f;
            for (let j = 1; j < d.length; j++) {
               item[this.tableHeaders[j].value] = d[j];
            }
            this.tableData.push(item);
         }

         //for the calculated table 'total' row
         this.tableData.forEach(row => {
            Object.keys(row).forEach(key => {
               const totalKey = 'TotalCol_' + key;
               const val = row[key];
               if (!isNaN(val) && typeof val === 'number') {
                  const keyHeader = this.tableHeaders.find(h => h.value === key);
                  if (hasOwn(keyHeader, totalKey))
                     keyHeader[totalKey] += val;
                  else
                     keyHeader[totalKey] = val;
               }
            });
         });

         this.tableHeaders.sort((a, b) => a.seq - b.seq);

         let sSeq = 0;
         let totalListCount = 0;
         let addCount;
         this.calculatedColumns.forEach(calCol => {
            addCount = totalListCount === 0;
            // alert(`calCol=${JSON.stringify(calCol)}`);
            const header = this.getHeader(calCol.name, calCol.name, true);
            const positionParts = calCol.position.split('::');
            if (positionParts[0] === 's')
               this.tableHeaders.splice(1 + sSeq++, 0, header);
            else {
               const ind = this.tableHeaders.findIndex(h => h.value.toLowerCase() === positionParts[1].toLowerCase());
               if (ind === -1)
                  this.tableHeaders.push(header);
               else if (ind === 0)
                  this.tableHeaders.splice(1, 0, header);
               else {
                  if (positionParts[0] === 'b')
                     this.tableHeaders.splice(ind, 0, header);
                  else
                     this.tableHeaders.splice(ind + 1, 0, header);
               }
            }

            const calColHeaders = this.findVariables(calCol.expression);
            this.tableData.forEach(d => {
               let resolvedExpr = calCol.expression;
               let error = '';
               calColHeaders.forEach(header => {
                  // alert(`header=${JSON.stringify(header)}`);
                  if (hasOwn(d, header)) {
                     resolvedExpr = resolvedExpr.replace('{{' + header + '}}', d[header]);
                  } else if (header === 'listCount' && this.reportId === 'account-groupby-event-list') {
                     let count;
                     const listParts = d.list ? d.list.split('-') : [];
                     if (listParts.length > 1) {
                        count = Number(listParts[listParts.length - 1]);
                        if (addCount) {
                           totalListCount += count;
                        }
                     }
                     resolvedExpr = resolvedExpr.replace('{{' + header + '}}', count ? count : 0);
                  } else {
                     const totalKey = header.replace('$$', 'TotalCol_');
                     const totalHeader = this.tableHeaders.find(h => hasOwn(h, totalKey));
                     if (totalHeader)
                        resolvedExpr = resolvedExpr.replace('{{' + header + '}}', totalHeader[totalKey]);
                     else {
                        error = `'${header}' not found!`;
                        return;
                     }
                  }
               });

               d[calCol.name] = error ? error : eval(resolvedExpr);
            });
         });

         // alert('totalListCount=' + totalListCount);

         //for total row resolvation
         this.calculatedColumns.forEach(calCol => {
            const calColHeaders = this.findVariables(calCol.expression);
            let resolvedExpr = calCol.expression;
            let error = '';
            calColHeaders.forEach(header => {
               if (header === 'listCount' && this.reportId === 'account-groupby-event-list') {
                  resolvedExpr = resolvedExpr.replace('{{' + header + '}}', totalListCount);
               } else {
                  let totalKey = 'TotalCol_' + header;
                  let totalHeader = this.tableHeaders.find(h => hasOwn(h, totalKey));
                  if (totalHeader) {
                     resolvedExpr = resolvedExpr.replace('{{' + header + '}}', totalHeader[totalKey]);
                  } else {
                     totalKey = header.replace('$$', 'TotalCol_');
                     totalHeader = this.tableHeaders.find(h => hasOwn(h, totalKey));
                     if (totalHeader)
                        resolvedExpr = resolvedExpr.replace('{{' + header + '}}', totalHeader[totalKey]);
                     else {
                        error = `'${header}' not found!`;
                        return;
                     }
                  }
               }
            });

            const tableHeader = this.tableHeaders.find(th => th.text === calCol.name);
            if (error) {
               //Do nothing and ignore if a variable in a calculated column doesn't exist.
               // tableHeader['TotalCol_' + calCol.name] = error;
            } else {
               const evalResult = eval(resolvedExpr);
               // alert(evalResult)
               if (!isNaN(evalResult) && Number.isFinite(evalResult) && evalResult)
                  tableHeader['TotalCol_' + calCol.name] = evalResult;
            }
         });

         // const seqHeaders = [];
         // for (let index = 0; index < this.tableHeaders.length; index++) {
         //    const h = this.tableHeaders.find(h => h.seq === index);
         //    seqHeaders.push(h);
         // }
         // alert('seqHeaders='+JSON.stringify(seqHeaders));
         // this.tableHeaders = seqHeaders;

         if (this.sortColumn && Object.keys(this.tableData[0]).includes(this.sortColumn)) {
            this.log('in init(): tableData (BEFORE sorting): ' + JSON.stringify(this.tableData));
            if (this.sortOrder === 'asc')
               this.tableData.sort((a, b) => (a[this.sortColumn] > b[this.sortColumn]) ? 1 : -1);
            else
               this.tableData.sort((a, b) => (a[this.sortColumn] < b[this.sortColumn]) ? 1 : -1);
         } else
            this.log(`in init(): sortColumn (${this.sortColumn}) doesn't exist.`);

         this.loadingTableData = false;
         this.log(`in init(): tableHeaders=${JSON.stringify(this.tableHeaders)}`);
         this.log(`in init(): tableData=${JSON.stringify(this.tableData)}`);
      },

      getHeader(label, id, addClass) {
         const header = { text: label, value: id, sortable: true};
         // if (this.options.hasOwnProperty('class'))
         //    header.class = this.options.class;
         // if (this.options.hasOwnProperty('align'))
         //    header.align = this.options.align;
         // else
            // header.align = 'start';

         if (this.headersAlignment)
            header.align = this.headersAlignment;
         else
            header.align = 'start';
            
         if (addClass)
            header.class = this.backgroundClass;
         return header;
      },

      findVariables(expression) {
         const startMark = "{{";
         const endMark = "}}";
         const regEx = /{{[^{]+}}/g;
         const matches = expression.match(regEx) || [];
         // console.log('matches=' + JSON.stringify(matches));
         const vars = [];

         matches.forEach(match => {
            const key = match.replace(startMark, '').replace(endMark, '');
            // console.log('match=' + match + ', key=' + key);
            if (key)
               vars.push(key);
         });

         // console.log('vars=' + JSON.stringify(vars));
         return vars;
      },

      cellClicked(rowName, colId, rowInd, colInd) {
         // alert('row=' + rowName + ', col=' + colId + ', rowInd=' + rowInd + ', colInd=' + colInd);
         let clickData = null;
         if (rowInd === this.lastClickedRowInd && colInd === this.lastClickedColInd) {
            this.lastClickedRowInd = -1;
            this.lastClickedColInd = -1;
         } else {
            clickData = {};
            this.lastClickedRowInd = rowInd;
            this.lastClickedColInd = colInd;

            clickData[this.rowDim] = this.chartData.find(d => d[0].f === rowName)[0].v;
            if (colId != 11111)
               clickData[this.colDim] = colId;
         }
         console.log(MSG + 'in cellClicked(): clickData=' + JSON.stringify(clickData));
         this.$emit('click', clickData);
      }
   },

   created() {
      // alert('headersSeq=' + JSON.stringify(this.headersSeq));
      // this.log(`in created(): chartData=${JSON.stringify(this.chartData)}`);
      // alert(`calculatedTable.chartData=${JSON.stringify(this.chartData)}`);
      //    this.myHeadersSeq = [];
      // if (this.headersSeq) {
      //    // this.myHeadersSeq = this.headersSeq.split(',');
      //    this.headersSeq.split(',').forEach(hs => {
      //       this.myHeadersSeq.push(parseInt(hs));
      //    });
      // } else {
      //    // this.myHeadersSeq = [];
      // }
      // alert('myHeadersSeq=' + JSON.stringify(this.myHeadersSeq));
      this.init();
   }
}
</script>
