<template>
   <!-- TODO:
   - BT: Add sample data for geo chart with state rdl.
   - BT: Find a way to click on edit btn programmatically (this is to handle erros on RF side)
   - should make the config error when it is copied? two cells cannot be exactly the same?
   - RF: Find out about chart types + resultLimits in one cell only?
   - RF: event fields
   - :flat="dashboard.definition.layout.flat" and :raised="dashboard.definition.layout.raised"
   -->
   <!-- HISTORY:   
   - V240716.1: Made preferences tab-based.
   - V240715.1: Added preferences panel.
   - V240610.1: Fixed a bug in saveDialogChart() that deleted sortColumn if there was no calculatedColumns.
   - V240604.1: Fixed the validation logic for sortColumn and sortOrder fields.
   - V240529.1: Added sortColumn and sortOrder fields and consumed them for CalculatedTable + Made eventSequence available for CalculatedTable only.
   - V240328.1: Updated file from Aref by adding analytic_str_1 & analytic_str_2 items to standardFieldsItems, and account-count-contacts-all item to kpiReportIDs +
      Removed click feature from KPI for the above reportID.
   - V230712.1: Passed reportId to the BtCalculatedTable component to handle specific calculations for listCount variable.
   - V230622.1: Fixed the bug that couldn't set the groupby_prop_field properly in init() + Removed .toLowerCase() from custom groupby_prop_field.
   - V230621.1: Added groupby_prop_field combobox to ChartConfig.
   - V230228.2: Added align-self to the Standard/Custom/Event fields dropdowns.
   - V230228.1: Added custonFields prop + Moved switches in <title> + Moved tags dropdown one rwo up + Added Custom Fields dropdown.
   - V230124.1: Copied the file from helpers project + Applied/Modified styles to display contents properly after Aref's upgrade +
      Replaced hasOwnProperty() with hasOwn() from mixins + Added v-on="on" to the addDashboard btn.
   - 09/01/22(B6.8): In chartReportIdChanged(), bypassed getChartData() call if chartData existed and reportId wasn't changed.
   - 09/01/22(B6.7): Added eventsSequence instead of preFilters as it couldn't be saved on Aref side. Then passed this value for headers-seq prop +
      Added validation to the eventsSequence field to be a numeric array.
   - 08/26/22(B6.6): For BtCalculatedTable: Added headersAlignment and customHeader + Passed preFilters to the BtCalculatedTable.
   - 01/20/22(B6.5): Consumed BtChartOptions component and replaced related <v-textarea>s + Added debug prop + 
      Added more default options for other chart types.
   - 12/02/21(B6.4): Consumed BtPrefilters component and replaced related <v-textarea>s.
   - 11/17/21(B6.3): Fixed issue with clickableField not being set.
   - 11/17/21(B6.2): Replaced 'answer' with 'formresponse'.
   - 11/12/21(B6.1): Changed chartReportIDs logic (Given by Aref).
   - 11/10/21(B6.0): Consumed BtClickableTable.
   - 10/18/21(B5.6): Added account-sum-scores to kpiReportIDs.
   - 10/13/21(B5.5): Changed the CalculatedTable UI to accept formula + Set align to 'start'.
   - 10/07/21(B5.4): Changed the CalculatedTable position's combobox with a v-menu model and added before/after options.
   - 10/05/21(B5.3): Added position property to the CalculatedTable config.
   - 10/05/21(B5.2): Removed height dependency for CalculatedTable.
   - 10/01/21(B5.1): Fixed bugs with calculatedColumns in chartConfig.
   - 09/30/21(B5.0): Consumed BtCalculatedTable.
   - 05/13/21(B4.11): Added preFilter to filters.
   - 05/11/21(B4.10): In configureFilter(), added audience to filter RDLs + Disabled the ConfigureFilter icon.
   - 04/07/21(B4.9): Changed 'Filter Where Clause' to 'Global Filter' + Made it to be a JSON object.
   - 01/25/21(B4.8): Converted MyDash.maxChartRows to a prop.
   - 12/10/20(B4.7): Fixed a bug in bt-autocomplete props (count, hint and label) related to length.
   - 12/10/20(B4.6): Removed a left over alert().
   - 12/09/20(B4.5): Added multipleSelect property to Filters + Replaced v-select with bt-autocomplete and consumed the new property.
   - 12/08/20(B4.4): Added gaugeMaxValue slider to kpiConfig and consumed it in dashboard.
   - 09/23/20(B4.3): Highlighted the clicked key in JSON VIEWER + Used label instead of slot for the slider.
   - 09/22/20(B4.2): Consumed kpi's gaugeColors prop.
   - 09/21/20(B4.1): Added btnAddShow and isPowerAdmin props.
   - 09/16/20(B4.0): Added DDL JSON viewer/editor.
   - 08/28/20(B3.3): Replaced useMasterDateRange switch with a dropdown and added new template names:
      internal to be default, master to replace useMasterDateRange switch, and ratio to display % only.
   - 08/21/20(B3.2): Added logic to handle tooltip functionality for unauthorized users + Allowed 6 KPIs.
   - 08/21/20(B3.1): Added excludedRows to chart Configs.
   - 08/12/20(B3.0): Added useMasterDateRange switch to KPI settings to hide kpi2Title and dateRange + Made groupby_custom_field in charts lower case.
   - 07/08/20(B2.10): Removed filter_custom_field property from filters if had no values.
   - 06/26/20(B2.9): Sorted all reportID items.
   - 06/19/20(B2.8): Only allowed one time customfield RDL in filters + Disabled copy feature if such a report is selected.
   - 06/17/20(B2.7): Changed the values of hide-details prop from "auto" to "false" as it created errors in Aref's version (2.1.9).
   - 06/15/20(B2.6): Added outlined and elevation props to be customizable + In Filter, added filter_custom_field if RDL is account-filter-customfield.
   - 05/28/20(B2.5): Added groupby_custom_field and sum_custom_field properties to charts. 
   - 05/28/20(B2.4): Fixed bugs with Kpis slider. 
   - 05/18/20(B2.3): Added slider to Kpis. 
   - 05/15/20(B2.2): Emptied list, selected and chartData properties in filters and charts in saveClicked(). 
   - 05/01/20(B2.1): Added cols="12" to filter and kpi columns + Fixed the bug that reset dashboard filters (used same variable as chart labels).
   - 04/28/20(B2.0): Cleaned up code by removing comments and styles + Added left and right navigations for filters and kpis +
      Added DonutChart to the chart types dropdown when applicable + Refactored move methods + re-located methods.
   - 04/28/20(B1.7): Added more properties to chart options + Fixed a bug that didn't empty prefilters and chart options +
      Fixed a bug that couldn't change datepicker type.
   - 04/27/20(B1.6): Set basic chart options for geo charts + Set the colors to colorAxis instead of the root.
   - 04/27/20(B1.5): Applied chart color selection to the charts dynamically.
   - 04/27/20(B1.4): Exchanged the xAxis/yAxis misunderstanding in getChartData().
   - 04/27/20(B1.3): Added sample data for GeoChart (RDLs ending with _city) + Fixed a bug that didn't re-render charts +
      Made chartData samles dynamically to use general labels for new charts.
   - 04/24/20(B1.2): Made the slider's min 4 instead of 1.
   - 04/23/20(B1.1): Added Gchart + Fixed a bug in chart config regarding chart type + Removed the check icon and made settings colorful +
      Moved all actions icons inside the cards rather being underneeth.
   - 04/21/20(B1.0): Added navigations to move chart cells around.
   - 04/14/20(B0.12): Added validation rules to the preFilters and chartOptions + Added filterWhereClause + Added formatNumbers to kpi config +
      For empty filters, added "show" property only instead of whole object + Added "id" to the standard fields + 
      Hid unwanted icons instead of disabling + Added "labels" property to the definition & charts.
   - 04/14/20(B0.11): Fixed a bug that couldn't open chart dialogs of previously saved dashboards.
   - 04/10/20(B0.10): Replaced some of <v-select> tags with <v-autocomplete> + Fixed the limit in number of chart rows.
   - 04/09/20(B0.9): Disabled chart settings if Audience is not selected + Fixed a bug that didn't clear colDIM and rowDIM +
      In KPI: Removed {} from empty prefilters + In Filters dropdown, only included configured filters +
      Added selectedEventsFields dropdown to the main dialog as well as KPIs and Charts dialogs + Allowed the total chart sizes to be less than 12 +
      Added switches for including the 1st filter or kpi, and allowed to delete them + Added resultLimit dropdown to the chart config. 
   - 04/08/20(B0.8): Setting charts column size w/sliders + Made filter and kpi optional + Added feature to reset 1st filter and kpi +
      Added validation for charts total columns + Added chartOptions
   - 03/25/20(B0.7): Displayed more info in the charts cells.
   - 03/25/20(B0.6): Fixed edit issue that reset charts settings + Reset kpi1 and kpi2 values to zero.
   - 03/24/20(B0.5): Added preFilters to KPIs and charts + Added preFilterHasPriority to charts + Fired cancel event for Save As Draft +
      Added isParent and isAdmin props to filter Audience items.
   - 03/23/20(B0.4): Placed colors dropdown in the dashboard instead of chart config + Made colors an array rather string.
   - 03/19/20(B0.3): Changed Audience texts + Reset all chart configs upon audience change +
      Filtered charts reportIDs based on audience value + Added btnAddDisabled prop + Replaced add/edit-dashboard events with save-dashboard +
      Set KPIs and Charts column sizes and IDs at exit + Removed extra row from charts in dashboard +
      Emptied Kpi.preFilters + Added colors dropdown and set it in chartOptions.
   - 03/19/20(B0.2): Made kpiReportIDs to be hard coded.
   -->
   <v-container fluid>
      <v-tooltip v-model="showTooltip" top>
         <template v-slot:activator="{ on }">
            <v-btn dark v-show="btnAddShow"
               v-on="on"
               :color="btnAddColor"
               :block="btnAddBlock"
               :disabled="btnAddDisabled"
               @click="btnAddDashboardClicked"
            >{{btnAddTitle}}
               <v-icon dark>{{btnAddIcon}}</v-icon>
            </v-btn>

            <v-btn class="ml-1"
               :color="btnEditColor"
               :block="btnEditBlock"
               :disabled="btnEditDisabled"
               @click="btnEditDashboardClicked"
            >{{btnEditTitle}}
               <v-icon dark>{{btnEditIcon}}</v-icon>
            </v-btn>
         </template>
         <span>{{noPermissionMessage}}</span>
      </v-tooltip>               

      <v-dialog persistent no-click-animation scrollable
         max-width="1200px"
         v-model="dialog"
      >
         <v-card>
            <v-card-title class="title grey--text darken-4 font-weight-bold pt-3 pb-0">
               {{dialogTitle}}
               <div class="flex-grow-1"></div>
               <v-switch class="switch pt-2 pb-0 pr-5"
                  label="Include Filter"
                  v-model="includeFilter"
                  :disabled="includeFilter===true"
                  @change="includeFilterChanged"
               ></v-switch>
               <v-switch class="switch pt-2 pb-0 pr-8"
                  label="Include KPI"
                  v-model="includeKpi"
                  :disabled="includeKpi===true"
                  @change="includeKpiChanged"
               ></v-switch>
            </v-card-title>
            <v-card-text class="pb-0" style="height: 95vh">
               <v-container>
                  <v-form ref="formMain" lazy-validation>
                     <v-row class="pt-2 pb-2">
                        <v-col class="py-0" cols="4">
                           <v-text-field persistent-hint required dense
                              ref="dashboardName"
                              type="text"
                              class="pt-0"
                              hint="Name of Dashboard"
                              v-model="dashboard.name"
                              :rules="[rules.required]"
                           ></v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="2">
                           <v-select dense persistent-hint hide-selected required
                              class="pt-0"
                              hint="Audience"
                              v-model="dashboard.audience"
                              :readonly="audienceItems.length===1"
                              :items="audienceItems"
                              :rules="[rules.required]"
                              @change="audienceChanged"
                           ></v-select>
                        </v-col>
                        <v-col class="py-0" cols="6">
                           <v-autocomplete dense persistent-hint hide-selected multiple small-chips deletable-chips
                              hint="Tags"
                              v-model="dashboard.tags"
                              :counter="tagItems.length"
                              :items="tagItems"
                           ></v-autocomplete>
                        </v-col>
                     </v-row>

                     <v-row class="pt-0 pb-0">
                        <v-col class="py-0" cols="4" align-self="end">
                           <v-autocomplete dense persistent-hint hide-selected multiple small-chips deletable-chips
                              hint="Standard Fields"
                              v-model="dashboard.definition.selectedContactsStandardFields"
                              :counter="standardFieldsItems.length"
                              :items="standardFieldsItems"
                           ></v-autocomplete>
                        </v-col>
                        <v-col class="py-0" cols="4" align-self="end">
                           <v-autocomplete dense persistent-hint hide-selected multiple small-chips deletable-chips
                              hint="Custom Fields"
                              v-model="dashboard.definition.selectedContactsCustomFields"
                              :counter="customFields.length"
                              :items="customFields"
                           ></v-autocomplete>
                        </v-col>
                        <v-col class="py-0" cols="4" align-self="end">
                           <v-autocomplete dense persistent-hint hide-selected multiple small-chips deletable-chips
                              hint="Event Fields"
                              v-model="dashboard.definition.selectedEventsFields"
                              :items="eventsItems"
                              :counter="eventsItems.length"
                           ></v-autocomplete>
                        </v-col>
                     </v-row>

                     <v-row class="pt-2">
                        <v-col class="py-0" cols="6">
                           <!-- v-model="dashboard.definition.filterWhereClause" -->
                           <v-textarea clearable dense outlined
                              class="pt-4"
                              label="Global Filter in JSON Format"
                              rows="2"
                              :hide-details="false"
                              :rules="[rules.validJson]"
                              v-model="globalFilter"
                           ></v-textarea>
                        </v-col>
                        <v-col class="py-0" cols="6">
                           <v-textarea clearable dense outlined
                              class="pt-4"
                              label="Labels"
                              rows="2"
                              :hide-details="false"
                              v-model="labels"
                              :rules="[rules.validJsonArray]"
                           ></v-textarea>
                        </v-col>
                     </v-row>

                     <v-row>
                        <v-col cols="12" class="pt-0 pb-5">
                           <v-expansion-panels focusable
                              v-model="panel"
                           >
                              <v-expansion-panel>
                                 <v-expansion-panel-header>
                                    <strong>Preferences</strong>
                                 </v-expansion-panel-header>
                                 <v-expansion-panel-content>

                                    <v-tabs dark
                                       class="elevation-2"
                                       background-color="grey lighten-2 accent-4"
                                       slider-color="black"
                                       v-model="tab"
                                    >
                                       <v-tab class="black--text">Switch Defaults</v-tab>
                                       <v-tab class="black--text">Theme</v-tab>
                                       <v-tab class="black--text">Permissions</v-tab>

                                       <v-tabs-items v-model="tab">
                                          <v-tab-item><!-- Switch Defaults tab -->
                                             <v-card flat tile>
                                                <v-card-text>
                                                   <v-row>
                                                      <v-col class="py-0" cols="2">
                                                         <v-switch class="switch pb-0"
                                                            label="Dashboards"
                                                            v-model="dashboard.definition.preferences.dashboards"
                                                         ></v-switch>
                                                      </v-col>
                                                      <v-col class="py-0" cols="2">
                                                         <v-switch class="switch pb-0"
                                                            label="Distinct"
                                                            v-model="dashboard.definition.preferences.distinct"
                                                         ></v-switch>
                                                      </v-col>
                                                      <v-col class="py-0" cols="2">
                                                         <v-switch class="switch pb-0"
                                                            label="Seed"
                                                            v-model="dashboard.definition.preferences.seed"
                                                         ></v-switch>
                                                      </v-col>
                                                   </v-row>
                                                </v-card-text>
                                             </v-card>
                                          </v-tab-item>

                                          <v-tab-item><!-- Theme tab -->
                                             <v-card flat tile>
                                                <v-card-text>
                                                   <v-row>
                                                      <v-col cols="12">
                                                         <v-autocomplete dense hide-selected persistent-hint required
                                                            hint="Chart Colors"
                                                            :items="chartColorsItems"
                                                            :rules="[rules.required]"
                                                            v-model="dashboard.definition.preferences.chartColors"
                                                            @change="chartColorsChanged"
                                                         ></v-autocomplete>
                                                      </v-col>
                                                   </v-row>
                                                </v-card-text>
                                             </v-card>
                                          </v-tab-item>

                                          <v-tab-item><!-- Permissions tab -->
                                             <v-card flat tile>
                                                <v-card-text>
                                                   <v-row>
                                                      <v-col class="py-0" cols="2">
                                                         <v-switch class="switch pb-0"
                                                            label="Can Clone"
                                                            v-model="dashboard.definition.preferences.canClone"
                                                         ></v-switch>
                                                      </v-col>
                                                      <v-col class="py-0" cols="3">
                                                         <v-switch class="switch pb-0"
                                                            label="Can Generate PDF"
                                                            v-model="dashboard.definition.preferences.canGeneratePDF"
                                                         ></v-switch>
                                                      </v-col>
                                                   </v-row>
                                                </v-card-text>
                                             </v-card>
                                          </v-tab-item>
                                       </v-tabs-items>
                                    </v-tabs>
                                 </v-expansion-panel-content>
                              </v-expansion-panel>
                           </v-expansion-panels>
                        </v-col>
                     </v-row>

                     <v-row class="pt-0 pb-2 mt-0">
                        <!-- <v-col class="py-0" cols="3">
                           <v-switch class="py-0"
                              v-model="dashboard.definition.layout.flat"
                           >
                              <template v-slot:label>
                                 <div class="body-2 pl-2 my-0">Flat: {{dashboard.definition.layout.flat}}</div>
                              </template>
                           </v-switch>
                        </v-col>
                        <v-col class="py-0" cols="3">
                           <v-switch class="py-0"
                              v-model="dashboard.definition.layout.raised"
                           >
                              <template v-slot:label>
                                 <div class="body-2 pl-2 my-0">Raised: {{dashboard.definition.layout.raised}}</div>
                              </template>
                           </v-switch>
                        </v-col> -->
                        <v-col class="py-0" cols="6">
                           <v-switch class="py-0"
                              :label="'Outlined: ' + dashboard.definition.layout.outlined"
                              v-model="dashboard.definition.layout.outlined"
                           >
                              <!-- <template v-slot:label>
                                 <div class="body-2 pl-2 my-0">Outlined: {{dashboard.definition.layout.outlined}}</div>
                              </template> -->
                           </v-switch>
                        </v-col>
                        <v-col class="py-0" cols="6">
                           <v-slider thumb-label inverse-label
                              class="pt-3" style="font-size: 14px"
                              min="0"
                              max="24"
                              :label="'Elevation: ' + dashboard.definition.layout.elevation"
                              v-model="dashboard.definition.layout.elevation"
                           >
                              <!-- <template v-slot:label>
                                 <div class="body-2 my-0">Elevation: {{dashboard.definition.layout.elevation}}</div>
                              </template> -->
                           </v-slider>
                        </v-col>
                     </v-row>
                  </v-form>

                  <v-card class="px-5 pb-2"
                     :outlined="dashboard.definition.layout.outlined"
                     :elevation="dashboard.definition.layout.elevation"
                  >
                     <v-row>
                        <v-col v-for="(item, i) in myDash.filters" :key="i"
                           cols="12" xs="12" sm="12" md="3" lg="3"
                           class="pt-0"
                        >
                           <!-- <v-select dense readonly
                              class="pb-0 mb-0"
                              prepend-inner-icon="filter"
                              :placeholder="item.name"
                              :error-messages="myDash.filtersSetting[i].message"
                           ></v-select> -->
                           <bt-autocomplete clearable deletable-chips persistent-hint small-chips single-line
                              class="pb-0 mb-0"
                              excluded-label="Exclude"
                              included-label="Include"
                              item-text="name"
                              item-value="id"
                              :counter="`${item.list ? item.list.length : 0}`"
                              :error-messages="myDash.filtersSetting[i].message"
                              :hint="`${item.selected && item.selected.length ? item.name : ''}`"
                              :is-included="true"
                              :items="item.list"
                              :label="`${item.selected && item.selected.length ? '' : item.name}`"
                              :multiple="item.multipleSelect"
                              :prepend-inner-icon="`${item.multipleSelect ? 'filter_list' : 'filter'}`"
                              v-model="item.selected"
                          ></bt-autocomplete>
                           <v-card flat class="d-flex flex-row">
                              <v-icon class="pr-1"
                                 :color="myDash.filtersSetting[i].isValid?validSettingClass:invalidSettingClass"
                                 :disabled="!dashboard.audience"
                                 @click="configureFilter(i)"
                              >settings</v-icon>
                              <v-icon class="pr-1" @click="deleteFilter(i)">delete_forever</v-icon>
                              <v-icon v-if="myDash.filters.length<maxFilters" 
                                 :disabled="item.reportId==='account-filter-customfield'" @click="duplicateFilter(i)">file_copy</v-icon>
                              <v-icon class="pl-1" v-if="myDash.filters.length<maxFilters && myDash.filters.length===i+1"
                                 @click="addFilterClicked(i)">add</v-icon>
                              <v-icon class="flex-grow-1"></v-icon>
                              <div v-if="myDash.filters.length>0">
                                 <v-icon v-if="i>0"
                                    @click="moveLeft('filters',i)">keyboard_arrow_left</v-icon>
                                 <v-icon v-if="i<myDash.filters.length-1"
                                    @click="moveRight('filters',i)">keyboard_arrow_right</v-icon>
                              </div>
                           </v-card>
                        </v-col>

                        <v-col xs="12" sm="12" md="3" lg="3" class="pt-4">
                           <bt-date-picker dd-readonly
                              :dd-placeholder="datePickerPlaceholder"
                              :dd-error-message="myDash.datePickerSetting.message"
                           ></bt-date-picker>
                           <v-card flat class="d-flex flex-row">
                              <v-icon 
                                 :color="myDash.datePickerSetting.isValid?validSettingClass:invalidSettingClass"
                                 @click="configureDatePicker()"
                              >settings</v-icon>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>

                  <v-row class="mt-2">
                     <v-col class="pb-0" cols="12" xs="12" sm="12" :md="myDash.kpis[i].columns" :lg="myDash.kpis[i].columns"
                        v-for="(item, i) in myDash.kpis" :key="i">
                        <v-card class="mb-0"
                           :outlined="dashboard.definition.layout.outlined"
                           :elevation="dashboard.definition.layout.elevation"
                        >
                              <!-- :has-kpi2="!item.useMasterDateRange" -->
                           <bt-kpi
                              :template-name="item.templateName"
                              :title="item.title"
                              :icon="item.icon"
                              :kpi1="item.kpi1"
                              :kpi2="item.kpi2"
                              :kpi2Title="item.kpi2Title"
                              :gauge-colors="kpiGaugeColors"
                              :gauge-max-value="item.gaugeMaxValue"
                           ></bt-kpi>
                           <v-card-actions class="pt-0 pl-0">
                              <v-icon class="pl-3 pr-1" 
                                 :color="myDash.kpisSetting[i].isValid?validSettingClass:invalidSettingClass"
                                 @click="configureKpi(i)"
                              >settings</v-icon>
                              <v-icon class="pr-1" @click="deleteKpi(i)">delete_forever</v-icon>
                              <v-icon class="pr-1" v-if="myDash.kpis.length<maxKpis" @click="duplicateKpi(i)">file_copy</v-icon>
                              <v-icon v-if="myDash.kpis.length<maxKpis && myDash.kpis.length===i+1"
                                 @click="addKpiClicked(i)">add</v-icon>
                              <div class="flex-grow-1"></div>
                              <v-slider v-if="myDash.kpis.length<maxKpis"
                                 dense hide-details ticks
                                 class="px-0 pb-0 ml-0 mr-2"
                                 min="2"
                                 :max="maxKpiColSize"
                                 step="1"
                                 thumb-label="always"
                                 tick-size="10"
                                 :thumb-size="16"
                                 v-model="myDash.kpis[i].columns"
                                 @change="kpiColSizeChanged"
                              ></v-slider>
                              <v-icon v-if="myDash.kpis.length>0 && i>0"
                                 @click="moveLeft('kpis',i)">keyboard_arrow_left</v-icon>
                              <v-icon v-if="myDash.kpis.length>0 && i<myDash.kpis.length-1"
                                 @click="moveRight('kpis',i)">keyboard_arrow_right</v-icon>
                           </v-card-actions>
                        </v-card>
                        <div class="v-text-field__details">
                           <div class="v-messages theme--light error--text" role="alert">
                              <div class="v-messages__wrapper" v-if="myDash.kpisSetting[i].message">
                                 <div class="v-messages__message pl-3">{{myDash.kpisSetting[i].message}}</div>
                              </div>
                           </div>
                        </div>
                     </v-col>
                  </v-row>

                  <v-row v-for="i in myDash.charts.length" :key="i">
                     <v-col class="pb-0" v-for="(item, j) in myDash.charts[i-1]" :key="j"
                        xs="12" sm="12" :md="myDash.charts[i-1][j].columns" :lg="myDash.charts[i-1][j].columns"
                     >
                        <v-card
                           class="mb-0 pt-2 pl-2"
                           :max-height="['CalculatedTable','ClickableTable'].includes(myDash.charts[i-1][j].chartType) ? '100%' : myDash.charts[i-1][j].chartOptions.height*1.6"
                           :outlined="dashboard.definition.layout.outlined"
                           :elevation="dashboard.definition.layout.elevation"
                        >
                           <div v-if="myDash.chartsSetting[i-1][j].isValid">
                              <v-card-title class="subtitle-1 grey--text darken-4 font-weight-bold py-0">{{myDash.charts[i-1][j].title}}</v-card-title>
                              <v-card-text v-if="myDash.charts[i-1][j].chartType==='CalculatedTable'"
                                 class="mt-2"
                              >
                                 <!-- :headers-seq="myDash.charts[i-1][j].preFilters && myDash.charts[i-1][j].preFilters.hasOwnProperty('event_ID') ? myDash.charts[i-1][j].preFilters.event_ID : []" -->
                                 <!-- myDash.charts[i-1][j].eventsSequence -->
                                 <bt-calculated-table
                                    :debug="debug"
                                    :chart-data="myDash.charts[i-1][j].chartData"
                                    :calculated-columns="myDash.charts[i-1][j].calculatedColumns"
                                    :options="myDash.charts[i-1][j].chartOptions"
                                    :row-dim="myDash.charts[i-1][j].rowDIM"
                                    :col-dim="myDash.charts[i-1][j].colDIM"
                                    :custom-header="myDash.charts[i-1][j].customHeader"
                                    :headers-alignment="myDash.charts[i-1][j].headersAlignment"
                                    :headers-seq="myDash.charts[i-1][j].eventsSequence ? JSON.parse(myDash.charts[i-1][j].eventsSequence) : []"
                                    :report-id="myDash.charts[i-1][j].reportId"
                                    :sort-column="myDash.charts[i-1][j].sortColumn"
                                    :sort-order="myDash.charts[i-1][j].sortOrder"
                                 ></bt-calculated-table>
                              </v-card-text>
                              <v-card-text v-else-if="myDash.charts[i-1][j].chartType==='ClickableTable'"
                                 class="mt-2"
                              >
                                 <bt-clickable-table
                                    :debug="debug"
                                    :chart-data="myDash.charts[i-1][j].chartData"
                                    :clickable-field="myDash.charts[i-1][j].clickableField"
                                    :event-fields="myDash.charts[i-1][j].eventFields"
                                    :groupby-field="myDash.charts[i-1][j].groupbyField"
                                    :headers="myDash.charts[i-1][j].headers"
                                 ></bt-clickable-table>
                              </v-card-text>
                              <GChart v-else
                                 :type="myDash.charts[i-1][j].chartType"
                                 :data="myDash.charts[i-1][j].chartData"
                                 :options="myDash.charts[i-1][j].chartOptions"
                                 :settings="chartSettings"
                              />
                           </div>
                           <div v-else>
                              <strong>Title: </strong>{{myDash.charts[i-1][j].title}}<br>
                              <strong>Report ID: </strong>{{myDash.charts[i-1][j].reportId}}<br>
                              <strong>Chart Type: </strong>{{myDash.charts[i-1][j].chartType}}<br>
                           </div>

                           <v-card-actions class="pt-3 pl-0">
                              <v-icon
                                 class="pl-1 pr-1"
                                 :color="myDash.chartsSetting[i-1][j].isValid?validSettingClass:invalidSettingClass"
                                 :disabled="!dashboard.audience"
                                 @click="configureChart(i-1, j)"
                              >settings</v-icon>
                              <v-icon
                                 class="pr-1"
                                 :disabled="myDash.charts.length === 1 && myDash.charts[0].length===1"
                                 @click="deleteChart(i-1, j)"
                              >delete_forever</v-icon>
                              <v-icon v-if="myDash.charts[i-1].length<maxChartCols"
                                 class="pr-1"
                                 @click="duplicateChart(i-1, j)"
                              >file_copy</v-icon>
                              <v-icon v-if="myDash.charts[i-1].length<maxChartCols && myDash.charts[i-1].length===j+1"
                                 @click="addChartClicked(i-1, j)"
                              >add</v-icon>
                              <!-- <v-icon v-if="i<myDash.maxChartRows && i===myDash.charts.length && myDash.charts[i-1].length===j+1" -->
                              <v-icon v-if="i<maxChartRows && i===myDash.charts.length && myDash.charts[i-1].length===j+1"
                                 @click="addChartClicked(i, -1)"
                              >keyboard_return</v-icon>
                              <div class="flex-grow-1"></div>
                              <v-slider dense hide-details ticks
                                 class="px-0 pb-0 ml-0 mr-2"
                                 min="4"
                                 max="12"
                                 step="1"
                                 thumb-label="always"
                                 tick-size="10"
                                 v-model="myDash.charts[i-1][j].columns"
                                 :thumb-size="16"
                                 @change="rerenderChart(i-1, j)"
                              ></v-slider>
                              <v-icon v-if="j > 0"
                                 @click="moveChartLeft(i-1, j)">keyboard_arrow_left</v-icon>
                              <v-icon v-if="j < myDash.charts[i-1].length - 1"
                                 @click="moveChartRight(i-1, j)">keyboard_arrow_right</v-icon>
                              <v-icon v-if="shouldShowDownArrow(i)"
                                 @click="moveChartDown(i-1, j)">keyboard_arrow_down</v-icon>
                              <v-icon v-if="i > 1 && myDash.charts[i-2].length < maxChartCols"
                                 @click="moveChartUp(i-1, j)">keyboard_arrow_up</v-icon>
                           </v-card-actions>
                        </v-card>

                        <div class="v-text-field__details">
                           <div class="v-messages theme--light error--text" role="alert">
                              <div class="v-messages__wrapper" v-if="myDash.chartsSetting[i-1][j].message">
                                 <div class="v-messages__message pl-3">{{myDash.chartsSetting[i-1][j].message}}</div>
                              </div>
                           </div>
                        </div>
                     </v-col>
                  </v-row>
               </v-container>
            </v-card-text>

            <v-card-actions class="mr-4">
               <v-btn text v-if="isPowerAdmin"
                  color="blue darken-1"
                  @click="jsonViewerClicked"
               >View JSON</v-btn>
               <div class="flex-grow-1"></div>
               <v-btn text
                  color="blue darken-1"
                  @click="cancelClicked"
               >Cancel</v-btn>
               <v-btn text
                  color="blue darken-1"
                  @click="saveClicked('draft')"
               >Save as Draft</v-btn>
               <v-btn text
                  color="blue darken-1"
                  @click="saveClicked('preview')"
               >Preview</v-btn>
               <v-btn text
                  color="blue darken-1"
                  @click="saveClicked('save')"
               >Save</v-btn>
            </v-card-actions>
         </v-card>

         <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
         </v-overlay>

      </v-dialog>


      <v-dialog persistent no-click-animation scrollable
         max-width="400px"
         v-model="dialogFilter"
      >
         <v-card class="px-4">
            <v-card-title class="title grey--text darken-4 font-weight-bold pl-3 pt-4 pb-0">Configure {{currFilterName}}:</v-card-title>
            <v-container >
               <v-form ref="formFilter" v-model="isFormFilterValid" lazy-validation>
                  <v-text-field persistent-hint required
                     ref="filterName"
                     class="pt-2"
                     hint="Name"
                     v-model="filterConfig.name"
                     :rules="[rules.required]"
                  ></v-text-field>
                  <v-autocomplete hide-selected persistent-hint required
                     v-model="filterConfig.reportId"
                     :items="filterReportIDs"
                     :hint="filterReportIdHint"
                     :rules="[rules.required]"
                     @change="filterReportIdChanged"
                  ></v-autocomplete>
                  <v-text-field v-if="filterConfig.reportId==='account-filter-customfield'"
                     persistent-hint required
                     ref="filterCustomField"
                     hint="Custom Field"
                     append-icon="title"
                     v-model="filterConfig.filter_custom_field"
                     :rules="[rules.required]"
                  ></v-text-field>
                  <bt-prefilters
                     class="pt-2"
                     :rows="2"
                     v-model="filterPreFilters"
                  ></bt-prefilters>
                  <v-switch dense
                     class="mt-0 pt-0"
                     label="Multiple Select"
                     v-model="filterConfig.multipleSelect"
                  ></v-switch>

                  <v-card-actions class="py-0 px-0">
                     <div class="flex-grow-1"></div>
                     <v-btn text
                        class="px-0"
                        color="blue darken-1"
                        @click="cancelDialogFilter"
                     >Cancel</v-btn>
                     <v-btn text
                        class="px-0 ml-0"
                        color="blue darken-1"
                        @click="saveDialogFilter"
                     >Save</v-btn>
                  </v-card-actions>
               </v-form>
            </v-container>
         </v-card>
      </v-dialog>


      <v-dialog v-model="dialogDatePicker" persistent no-click-animation max-width="360px" scrollable>
         <v-card class="px-4">
            <v-card-title class="title grey--text darken-4 font-weight-bold pl-3 pt-4 pb-0">Configure Date Picker</v-card-title>
            <v-container class="pb-2">
               <v-form ref="formDatePicker" v-model="isFormDatePickerValid" lazy-validation>
                  <v-select hide-selected persistent-hint required
                     hint="Type of Date Picker"
                     v-model="datePickerConfig.type"
                     :items="datePickerTypes"
                     :rules="[rules.required]"
                     @change="datePickerTypeChanged"
                  ></v-select>
                  <v-select v-if="datePickerConfig.type"
                     hide-selected persistent-hint required
                     v-model="datePickerConfig.defaultId"
                     :hint="datePickerSelectLabel"
                     :items="datePickerSelectItems"
                     :rules="[rules.required]"
                  ></v-select>
                  <v-select v-if="datePickerConfig.type==='range'"
                     hide-selected persistent-hint required
                     hint="Maximum Range"
                     v-model="datePickerConfig.maxRange"
                     :items="datePickerMaxRangeItems"
                     :rules="[rules.required]"
                  ></v-select>
                  <v-card-actions class="pt-4 pr-0 pb-0">
                     <div class="flex-grow-1"></div>
                     <v-btn text
                        class="px-0"
                        color="blue darken-1"
                        @click="cancelDialogDatePicker"
                     >Cancel</v-btn>
                     <v-btn text
                        class="px-0 ml-0"
                        color="blue darken-1"
                        @click="saveDialogDatePicker"
                     >Save</v-btn>
                  </v-card-actions>
               </v-form>
            </v-container>
         </v-card>
      </v-dialog>


      <v-dialog no-click-animation persistent scrollable
         max-width="760px"
         v-model="dialogKpi"
      >
         <v-card class="px-4 pt-3">
            <v-card-title class="pl-3 pt-3 pb-5">
               <v-row>
                  <v-col class="py-0" xs="12" sm="6" md="6" lg="6">
                     <div class="title grey--text darken-4 font-weight-bold">Configure KPI</div>
                  </v-col>
                  <v-spacer></v-spacer>
               <!-- <div class="flex-grow-1"></div> -->
               <!-- <v-btn text class="caption pr-0" color="blue darken-1" @click="populateKpiConfig()">populate test data</v-btn> -->
               <!-- <v-switch
                  :label="`Use Ratio: ${kpiConfig.useRatio?'Yes':'No'}`"
                  v-model="kpiConfig.useRatio"
                  @change="useRatioChanged"
               ></v-switch>
               <v-switch
                  class="pl-2"
                  :label="`Use Master Date Range: ${kpiConfig.useMasterDateRange?'Yes':'No'}`"
                  :disabled="kpiConfig.useRatio"
                  v-model="kpiConfig.useMasterDateRange"
               ></v-switch> -->
                  <v-col class="py-0 pl-4 pr-0 body-2 font-weight-regular" xs="12" sm="6" md="6" lg="6">
                     <v-select dense hide-selected persistent-hint
                        hint="Template Name"
                        append-icon="article"
                        :items="kpiTemplateNameItems"
                        v-model="kpiConfig.templateName"
                     ></v-select>
                  </v-col>
               </v-row>
            </v-card-title>
            <v-container class="py-0">
               <v-form ref="formKpi" v-model="isFormKpiValid" lazy-validation>
                  <v-row class="pt-2">
                     <v-col class="py-0" xs="12" sm="12" md="6" lg="6">
                        <v-text-field dense persistent-hint required
                           ref="kpiTitle"
                           class="pt-0"
                           hint="First Title (Top)"
                           append-icon="title"
                           v-model="kpiConfig.title"
                           :rules="[rules.required]"
                        ></v-text-field>
                     </v-col>
                     <v-col class="py-0" xs="12" sm="12" md="6" lg="6">
                        <v-text-field dense persistent-hint required
                           class="pt-0"
                           hint="Icon"
                           v-model="kpiConfig.icon"
                           :append-icon="kpiConfig.icon"
                           :rules="[rules.required]"
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  <v-row v-if="kpiConfig.templateName === kpiTemplateNames.internal">
                     <v-col class="py-0" xs="12" sm="12" md="6" lg="6">
                        <v-text-field dense persistent-hint required
                           class="pt-2"
                           hint="Second Title (Bottom)"
                           append-icon="title"
                           v-model="kpiConfig.kpi2Title"
                           :rules="[rules.required]"
                        ></v-text-field>
                     </v-col>
                     <v-col class="py-0" xs="12" sm="12" md="6" lg="6">
                        <v-select dense hide-selected persistent-hint required
                           class="pt-2"
                           hint="Date Range"
                           append-icon="date_range"
                           v-model="kpiConfig.kpi1DateRange"
                           :items="kpiDateRangeItems"
                           :rules="[rules.required]"
                           @change="kpiDateRangeChanged"
                        ></v-select>
                     </v-col>
                  </v-row>
                  <v-slider v-if="kpiConfig.templateName === kpiTemplateNames.ratio"
                     dense hide-details ticks
                     v-model="kpiConfig.gaugeMaxValue"
                     class="pt-5 mt-2 pb-2"
                     label="Gauge Max Value:"
                     max="100"
                     min="0"
                     step="1"
                     thumb-label="always"
                     thumb-size="24"
                  ></v-slider>
                  <v-row>
                     <v-col class="py-0" xs="12" sm="12" md="6" lg="6">
                        <v-autocomplete dense hide-selected persistent-hint required
                           class="pt-2"
                           hint="Rport ID"
                           v-model="kpiConfig.reportId"
                           :items="kpiReportIDs"
                           :rules="[rules.required]"
                        ></v-autocomplete>
                     </v-col>
                     <v-col class="py-0" xs="12" sm="12" md="6" lg="6">
                        <v-switch
                           class="pt-2"
                           v-model="kpiConfig.formatNumbers"
                           :label="`Format Numbers: ${kpiConfig.formatNumbers?'Yes':'No'}`"
                        ></v-switch>
                     </v-col>
                  </v-row>
                  <bt-prefilters
                     class="pt-2 pb-0"
                     :rows="2"
                     v-model="kpiPreFilters"
                  ></bt-prefilters>
                  <bt-prefilters v-if="kpiConfig.templateName === kpiTemplateNames.ratio"
                     label="Prefilters2 in JSON Format"
                     my-class="pt-0"
                     :rows="2"
                     v-model="kpiPreFilters2"
                  ></bt-prefilters>
                  <!-- <v-row v-if="kpiConfig.useRatio">
                     <v-col class="py-0" xs="12" sm="12" md="6" lg="6">
                        <v-text-field persistent-hint required
                           ref="redTo"
                           class="pt-0"
                           type="number"
                           hint="Red-to Percentage"
                           min="1"
                           max="100"
                           :rules="[rules.required, rules.redTo]"
                           v-model="kpiConfig.redTo"
                        ></v-text-field>
                     </v-col>
                     <v-col class="py-0" xs="12" sm="12" md="6" lg="6">
                        <v-text-field persistent-hint required
                           ref="yellowTo"
                           class="pt-0"
                           type="number"
                           hint="Yellow-to Percentage"
                           :min="`${parseInt(kpiConfig.redTo) + 1}`"
                           max="100"
                           :disabled="!kpiConfig.redTo"
                           v-model="kpiConfig.yellowTo"
                        ></v-text-field>
                        <v-text-field persistent-hint required
                           ref="yellowTo"
                           class="pt-0"
                           type="number"
                           hint="Yellow-to Percentage"
                           append-outer-icon="add"
                           prepend-icon="remove"
                           :min="`${parseInt(kpiConfig.redTo) + 1}`"
                           max="100"
                           :disabled="!kpiConfig.redTo"
                           v-model="kpiConfig.yellowTo"
                           @click:append-outer="incrementNumber('yellow')"
                           @click:prepend="decrementNumber('yellow')"
                        ></v-text-field>
                     </v-col>
                  </v-row> -->
                  <v-select dense hide-selected multiple persistent-hint small-chips deletable-chips
                     class="pt-1"
                     hint="Filters"
                     v-model="kpiConfig.impactByFilters"
                     :items="filterItems"
                     :counter="filterItems.length"
                  ></v-select>
                  <v-autocomplete dense hide-selected multiple persistent-hint small-chips deletable-chips
                     class="pt-2"
                     hint="Event Fields"
                     v-model="kpiConfig.selectedEventsFields"
                     :items="eventsItems"
                     :counter="eventsItems.length"
                  ></v-autocomplete>

                  <v-card-actions class="pt-4 px-0 mx-0">
                     <div class="flex-grow-1"></div>
                     <v-btn text
                        class="mx-0 px-0"
                        color="blue darken-1"
                        @click="cancelDialogKpi"
                     >Cancel</v-btn>
                     <v-btn text
                        class="mx-0 px-0"
                        color="blue darken-1"
                        @click="saveDialogKpi"
                     >Save</v-btn>
                  </v-card-actions>
               </v-form>
            </v-container>
         </v-card>
      </v-dialog>


      <v-dialog persistent no-click-animation scrollable
         max-width="720px"
         v-model="dialogChart"
      >
         <v-card class="px-4">
            <v-card-title class="title grey--text darken-4 font-weight-bold pl-3 pt-3 pb-0">Configure Chart {{currChartTitle}}:</v-card-title>
            <v-card-text class="px-3 pb-2">
               <div v-if="chartConfigError" class="red--text">{{chartConfigError}}</div>
               <v-form lazy-validation
                  ref="formChart"
                  v-model="isFormChartValid"
               >
                  <v-row class="pt-3">
                     <v-col class="pt-2 pb-0" xs="12" sm="12" md="8" lg="8">
                        <v-text-field dense persistent-hint required
                           ref="chartName"
                           class="pt-0"
                           hint="Chart Title"
                           append-icon="title"
                           v-model="chartConfig.title"
                           :rules="[rules.required]"
                        ></v-text-field>
                     </v-col>
                     <v-col class="pt-0 pb-0" xs="12" sm="12" md="4" lg="4">
                        <v-select dense hide-selected persistent-hint
                           class="pt-2"
                           hint="Result Limit"
                           v-model="chartConfig.resultLimit"
                           :items="resultLimitItems"
                        ></v-select>
                     </v-col>
                  </v-row>
                  <v-row>
                     <v-col class="pt-0 pb-2" xs="12" sm="12" md="8" lg="8">
                        <v-autocomplete dense hide-selected persistent-hint required
                           class="pt-2"
                           v-model="chartConfig.reportId"
                           :search-input="searchInput"
                           :hint="chartReportIdHint"
                           :items="chartReportIDs"
                           :rules="[rules.required]"
                           @change="chartReportIdChanged"
                        ></v-autocomplete>
                     </v-col>
                     <v-col class="pt-0 pb-2" xs="12" sm="12" md="4" lg="4">
                        <v-select dense hide-selected persistent-hint required
                           class="pt-2"
                           hint="Chart Type"
                           append-icon="insert_chart_outlined"
                           v-model="chartConfig.chartType"
                           :items="chartTypes"
                           :rules="[rules.required]"
                           @change="chartTypeChanged"
                        ></v-select>
                     </v-col>
                  </v-row>

                  <div v-if="chartConfig.chartType === 'CalculatedTable'">
                     <v-textarea clearable dense outlined persistent-hint readonly
                        ref="chartCalCol"
                        class="pt-5 pr-0 mb-0"
                        label="Calculated Columns in JSON Format"
                        rows="2"
                        append-icon="edit"
                        :hide-details="false"
                        v-model="chartCalculatedColumns"
                        @click:append="editCalculatedColumns"
                        @click:clear="clearCalculatedColumns"
                     ></v-textarea>
                     <v-row>
                        <v-col xs="12" sm="12" md="3" lg="3" class="pt-0">
                           <v-select dense persistent-hint hide-selected required
                              v-model="chartConfig.headersAlignment"
                              ref="alignment"
                              class="pt-0"
                              hint="Columns Alignment"
                              :items="headersAlignmentItems"
                           ></v-select>
                        </v-col>
                        <v-col xs="12" sm="12" md="3" lg="3" class="pt-0 pl-0">
                           <v-text-field dense persistent-hint
                              ref="customHeader"
                              autocomplete="off"
                              class="pt-0"
                              hint="Label for the 1st Column"
                              v-model="chartConfig.customHeader"
                           ></v-text-field>
                        </v-col>
                        <v-col xs="12" sm="12" md="6" lg="6" class="pt-0">
                           <v-text-field dense persistent-hint
                              ref="eventsSeq"
                              autocomplete="off"
                              class="pt-0"
                              hint="Events Sequence"
                              :rules="[rules.validNumericArray]"
                              v-model="chartConfig.eventsSequence"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col xs="12" sm="12" md="6" lg="6" class="pt-0">
                           <v-text-field dense persistent-hint
                              ref="sortColumn"
                              autocomplete="off"
                              class="pt-0"
                              hint="Column to Sort on"
                              :rules="[rules.number]"
                              v-model="chartConfig.sortColumn"
                           ></v-text-field>
                        </v-col>
                        <v-col xs="12" sm="12" md="6" lg="6" class="pt-0">
                           <v-select dense persistent-hint hide-selected required
                              ref="sortOrder"
                              class="pt-0"
                              hint="Sort Order"
                              :disabled="!chartConfig.sortColumn"
                              :items="sortOrderItems"
                              :rules="[rules.requiredSortOrder]"
                              v-model="chartConfig.sortOrder"
                           ></v-select>
                        </v-col>
                     </v-row>
                  </div>

                  <!-- <v-row v-if="chartConfig.reportId==='account-groupby-customfield-count' || chartConfig.reportId==='account-groupby-customfield-sum'"> -->
                  <v-row v-if="chartConfig.reportId && (chartConfig.reportId.startsWith('account-groupby-customfield-count') || chartConfig.reportId.startsWith('account-groupby-customfield-sum'))">
                     <v-col class="pt-0 pb-2" xs="12" sm="12" :md="customFieldColSize" :lg="customFieldColSize">
                        <v-text-field dense persistent-hint required
                           ref="groupbyCustomField"
                           class="pt-0"
                           hint="Groupby Custom Field"
                           append-icon="title"
                           v-model="chartConfig.groupby_custom_field"
                           :rules="[rules.required]"
                        ></v-text-field>
                     </v-col>
                     <v-col v-if="chartConfig.reportId==='account-groupby-customfield-sum'"
                        class="pt-0 pb-2" xs="12" sm="12" :md="customFieldColSize" :lg="customFieldColSize">
                        <v-text-field dense persistent-hint required
                           ref="sumCustomField"
                           class="pt-0"
                           hint="Sum Custom Field"
                           append-icon="title"
                           v-model="chartConfig.sum_custom_field"
                           :rules="[rules.required]"
                        ></v-text-field>
                     </v-col>
                  </v-row>

                  <v-combobox v-if="chartConfig.reportId && chartConfig.reportId.indexOf('-prop') > -1"
                     dense hide-selected persistent-hint
                     class="pt-5 pb-0"
                     ref="`groupbyPropField`"
                     autocomplete="off"
                     hint="Groupby Prop Field"
                     placeholder="select or type..."
                     :items="eventOptionFields"
                     :rules="[rules.required]"
                     v-model="chartConfig.groupby_prop_field"
                  ></v-combobox>

                  <v-autocomplete dense persistent-hint hide-selected multiple small-chips deletable-chips
                     class="pt-4"
                     hint="Event Fields"
                     v-model="chartConfig.selectedEventsFields"
                     :items="eventsItems"
                     :counter="eventsItems.length"
                  ></v-autocomplete>

                  <v-textarea clearable dense outlined
                     class="pt-5"
                     label="Excluded Rows in Array of Strings Format"
                     rows="2"
                     v-model="chartExcludedRows"
                     :rules="[rules.validJsonArray]"
                  ></v-textarea>
                  <!-- <v-textarea clearable dense outlined persistent-hint
                     ref="chartPreFilters"
                     class="pt-3"
                     label="Prefilters in JSON Format"
                     rows="2"
                     v-model="chartPreFilters"
                     :hide-details="false"
                     :rules="[rules.validJson]"
                  ></v-textarea> -->
                  <bt-prefilters
                     my-class="pt-2"
                     :rows="2"
                     v-model="chartPreFilters"
                  ></bt-prefilters>
                  <v-switch v-if="chartPreFilters"
                     class="pt-0 mt-0"
                     v-model="chartConfig.preFilterHasPriority"
                     :label="`Prefilter Has Priority: ${'preFilterHasPriority' in chartConfig ? chartConfig.preFilterHasPriority.toString() : 'false'}`"
                  ></v-switch>

                  <bt-chart-options v-if="chartConfig.chartType != 'ClickableTable'"
                     class="pt-3"
                     :chart-type="chartConfig.chartType"
                     :debug="debug"
                     label="Chart Options in JSON Format"
                     :rows="3"
                     :rules="[rules.validJson]"
                     v-model="chartOptions"
                  ></bt-chart-options>

                  <v-textarea v-if="chartConfig.chartType != 'ClickableTable'"
                     clearable dense outlined
                     label="Chart Labels in JSON Array Format"
                     rows="2"
                     v-model="chartLabels"
                     :rules="[rules.validJsonArray]"
                  ></v-textarea>

                  <v-card-actions class="py-0 pr-0">
                     <div class="flex-grow-1"></div>
                     <v-btn text
                        class="px-0"
                        color="blue darken-1"
                        @click="cancelDialogChart"
                     >Cancel</v-btn>
                     <v-btn text
                        class="px-0 ml-0"
                        color="blue darken-1"
                        @click="saveDialogChart"
                     >Save</v-btn>
                  </v-card-actions>
               </v-form>
            </v-card-text>
         </v-card>
      </v-dialog>


      <v-dialog persistent no-click-animation scrollable
         max-width="1200px"
         v-model="dialogCalCol"
      >
         <v-card class="px-4">
            <v-card-title class="title grey--text darken-4 font-weight-bold pl-3 pt-3 pb-0">Configure Calculated Columns:</v-card-title>
            <v-container>
               <v-form lazy-validation
                  ref="formCalCol"
                  v-model="isFormCalColValid"
               >
                  <v-row v-for="(item, i) in calculatedColumns" :key="i">
                     <!-- placeholder="specify the column name" -->
                     <v-col class="pt-0 pb-2" xs="12" sm="12" md="2" lg="2">
                        <v-text-field persistent-hint
                           class="pt-0"
                           autocomplete="off"
                           hint="Name"
                           :rules="[rules.duplicate]"
                           v-model="item.name"
                           @change="addCalCol"
                        >
                           <template v-slot:prepend>
                              <v-icon
                                 class="py-2"
                                 color="grey darken-1"
                                 :disabled="!item.name"
                                 @click="removeCalCol(i)"
                              >delete_forever</v-icon>
                           </template>
                        </v-text-field>
                     </v-col>
                     <v-col class="pt-0 pb-2" xs="12" sm="12" md="5" lg="5" v-if="item.name">
                        <v-text-field persistent-hint required
                           class="pt-0"
                           hint="Calculation Expression"
                           :placeholder="`{{col1}}/{{col2}}*100 OR {{col1}}+{{col2}}`"
                           v-model="item.expression"
                           :rules="[rules.required,rules.expression]"
                        ></v-text-field>
                     </v-col>
                     <v-col class="pt-0 pb-2" xs="12" sm="12" md="2" lg="2" v-if="item.name">
                        <v-menu close-on-content-click offset-y bottom
                           class="pt-0"
                           min-width="auto"
                           transition="scale-transition"
                           v-model="item.fix.menu"
                        >
                           <template v-slot:activator="{ on, attrs }">
                              <v-text-field persistent-hint required
                                 :ref="`fix_${item.name}`"
                                 class="pt-0"
                                 autocomplete="off"
                                 append-icon="arrow_drop_down"
                                 :hint="item.fix.hint"
                                 :placeholder="item.fix.placeholder"
                                 :readonly="item.fix.list === 'none'"
                                 :rules="[rules.required]"
                                 v-model="item.fix.text"
                                 v-bind="attrs"
                                 v-on="on"
                              ></v-text-field>
                           </template>
                           <v-list dense class="py-0">
                              <v-list-item v-for="(listItem, index) in calColFixItems" :key="index"
                                 @click="calColFixMenuChanged(listItem, item)"
                              >
                                 <!-- <v-list-item-icon>
                                    <v-icon v-text="item.icon"></v-icon>
                                 </v-list-item-icon> -->
                                 <v-list-item-content>
                                    <v-list-item-title v-text="listItem.text"></v-list-item-title>
                                 </v-list-item-content>
                              </v-list-item>
                           </v-list>
                        </v-menu>
                     </v-col>
                     <v-col class="pt-0 pb-0" xs="12" sm="12" md="3" lg="3" v-if="item.name">
                        <v-menu close-on-content-click offset-y bottom
                           class="pt-0"
                           min-width="auto"
                           transition="scale-transition"
                           v-model="item.position.menu"
                        >
                           <template v-slot:activator="{ on, attrs }">
                              <v-text-field persistent-hint required
                                 :ref="item.name"
                                 class="pt-0"
                                 autocomplete="off"
                                 append-icon="arrow_drop_down"
                                 :placeholder="item.position.placeholder"
                                 :hint="item.position.hint"
                                 :readonly="['s','e'].includes(item.position.list)"
                                 :rules="[rules.required]"
                                 v-model="item.position.text"
                                 v-bind="attrs"
                                 v-on="on"
                              ></v-text-field>
                           </template>
                           <v-list dense class="py-0">
                              <v-list-item v-for="(listItem, index) in calColPositionItems" :key="index"
                                 @click="calColPositionMenuChanged(listItem, item)"
                              >
                                 <!-- <v-list-item-icon>
                                    <v-icon v-text="item.icon"></v-icon>
                                 </v-list-item-icon> -->
                                 <v-list-item-content>
                                    <v-list-item-title v-text="listItem.text"></v-list-item-title>
                                 </v-list-item-content>
                              </v-list-item>
                           </v-list>
                        </v-menu>
                     </v-col>
                  </v-row>
               </v-form>
            </v-container>
            <v-card-actions class="py-0 pr-0">
               <div class="flex-grow-1"></div>
               <!-- <v-btn text
                  class="px-0"
                  color="blue darken-1"
                  :disabled="resetDisabled"
                  @click="resetCalCol"
               >Reset</v-btn> -->
               <v-btn text
                  class="px-0"
                  color="blue darken-1"
                  @click="cancelCalCol"
               >Cancel</v-btn>
               <v-btn text
                  class="px-0 ml-0"
                  color="blue darken-1"
                  :disabled="!isFormCalColValid"
                  @click="saveCalCol"
               >Save</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

      <v-dialog persistent no-click-animation scrollable
         max-width="1200px"
         v-model="dialogJsonViewer"
      >
         <v-card class="px-4">
            <v-card-title class="title grey--text darken-4 font-weight-bold pl-3 pt-4 pb-0">View/Edit DDL Object:</v-card-title>
               <v-card-text>
                  <v-row>
                     <v-col cols="7" class="pt-5 pb-0 mb-0">
                        <v-slider dense
                           class="pt-2 mx-0 body-2"
                           min="1"
                           max="10"
                           tick-size="10"
                           thumb-label="always"
                           :thumb-size="16"
                           :label="`Collapsing Depth: ${jsonPrettyDeep}`"
                           v-model="jsonPrettyDeep"
                        >
                           <!-- <template v-slot:label>
                              <div class="body-2 my-0">Collapsing Depth: {{jsonPrettyDeep}}</div>
                           </template> -->
                        </v-slider>
                     </v-col>
                     <v-col cols="5" class="pt-5 mt-1">
                        <v-btn block v-if="isNavigator"
                           color="primary"
                           :hint="copyToClipboardHint"
                           @click="copyToClipboard"
                        >Copy to Clipboard
                           <v-icon dark class="pl-1">content_copy</v-icon>
                        </v-btn>
                     </v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="7" class="pt-0">
                        <v-card>
                           <json-pretty
                              path=""
                              :data="prettyDashboard"
                              :deep="jsonPrettyDeep"
                              :show-length="true"
                              :show-line="true"
                              :show-double-quotes="false"
                              :highlight-mouseover-node="true"
                              :show-select-controller="true"
                              :select-on-click-node="true"
                              :highlight-selected-node="true"
                              v-model="prettyDashboardStringified"
                              @click="jsonItemClicked"
                           ></json-pretty>
                        </v-card>
                     </v-col>
                     <v-col cols="5" class="pt-0">
                        <v-textarea counter dense outlined persistent-hint
                           ref="jsonPretty"
                           class="caption"
                           :auto-grow="autoGrow"
                           v-model="prettyDashboardStringified"
                        ></v-textarea>
                     </v-col>
                  </v-row>
               </v-card-text>
               <v-card-actions class="pt-2 pr-0">
                  <div class="flex-grow-1"></div>
                  <v-btn text
                     class="px-0"
                     color="blue darken-1"
                     @click="cancelDialogJsonViewer"
                  >Cancel</v-btn>
                  <v-btn text
                     class="px-0 ml-0"
                     color="blue darken-1"
                     @click="saveDialogJsonViewer"
                  >Save</v-btn>
               </v-card-actions>
         </v-card>
      </v-dialog>

   </v-container>
</template>

<script>
import BtAutocomplete from "../components/BtAutocomplete.vue";
import BtDatePicker from "../components/BtDatePicker.vue";
import BtKpi from "../components/BtKpi.vue";
import { GChart } from 'vue-google-charts';
import JsonPretty from 'vue-json-pretty';
import BtCalculatedTable from './BtCalculatedTable.vue';
import BtClickableTable from './BtClickableTable.vue';
import BtPrefilters from './BtPrefilters.vue';
import BtChartOptions from './BtChartOptions.vue';
import { hasOwn } from '../mixins/bt-mixin.js';

// const DEBUG = false;
let DEBUG;
const SETTING_MESSAGE = 'Need to be configured! ';
const KPI_TEMPLATE_NAMES = {
   internal: 'internal-date-range',
   master: 'master-date-range',
   ratio: 'ratio-with-master-date-range'
}
const CHARTDATA_COUNT = [
   [
      {"id":"?_id","label":"?_name","format":"string"},
      {"id":"contactscount","label":"Contacts","format":"number"}
   ],
   [{"v":"?_id_1","f":"? 1"},24955],
   [{"v":"?_id_2","f":"? 2"},8505],
   [{"v":"?_id_3","f":"? 3"},512],
   [{"v":"?_id_4","f":"? 4"},165],
   [{"v":"?_id_5","f":"? 5"},11]
   // [
   //    {"id":"program_id","label":"program_name"},
   //    {"id":"20102","label":"Email sent to Contact"},
   //    {"id":"20103","label":"Contact opened Email"},
   //    {"id":"20104","label":"Contact opted-out of Email"},
   //    {"id":"20105","label":"Contact clicked a link in Email"},
   //    {"id":"20106","label":"Contact marked Email as spam"},
   //    {"id":"20107","label":"Email soft bounced"},
   //    {"id":"20108","label":"Email hard bounced"},
   //    {"id":"20109","label":"Email failed to process"},
   //    {"id":"20110","label":"Suppressed"}
   // ],
   // [{"v":"512","f":"9-24-21 Webinar Email 2"},0,4277,53,1264,0,10342,152,70,17618],
   // [{"v":"510","f":"9-24-21 Webinar Email 1"},27787,4563,52,1243,2,1452,57,2,1647],
   // [{"v":"513","f":"9-24-21 Webinar Email 3"},27762,6272,88,1317,1,1463,6,2,1718],
   // [{"v":"518","f":"9-24-21 Webinar Email 4"},27667,5240,55,147,1,1415,16,2,1724],
   // [{"v":"514","f":"Updated Studio Training"},506,130000,1,32,0,103,0,0,173],
   // [{"v":"500","f":"Extra"},506,130000,1,32,0,103,0,0,173]
];
const CHARTDATA_CITY = [
   [
      {"id":"city","label":"city"},
      {"id":"5","label":"?-name"}
   ],
   [{"v":", ","f":", "},53],
   [{"v":"CLERMONT, FL","f":"CLERMONT, FL"},2],
   [{"v":"Eustis, FL","f":"Eustis, FL"},1],
   [{"v":"FLEMING ISLAND, FL","f":"FLEMING ISLAND, FL"},3],
   [{"v":"Fleming Island, FL","f":"Fleming Island, FL"},1],
   [{"v":"GULF SHORES, AL","f":"GULF SHORES, AL"},6],
   [{"v":"Gulf Breeze, FL","f":"Gulf Breeze, FL"},1],
   [{"v":"HOMOSASSA, FL","f":"HOMOSASSA, FL"},3],
   [{"v":"JACKSONVILLE BEACH, FL","f":"JACKSONVILLE BEACH, FL"},3],
   [{"v":"LADY LAKE, FL","f":"LADY LAKE, FL"},3],
   [{"v":"LECANTO, FL","f":"LECANTO, FL"},1],
   [{"v":"NICEVILLE, FL","f":"NICEVILLE, FL"},2],
   [{"v":"Navarre, FL","f":"Navarre, FL"},1],
   [{"v":"OCALA, FL","f":"OCALA, FL"},15],
   [{"v":"PALATKA, FL","f":"PALATKA, FL"},2],
   [{"v":"PANAMA CITY BEACH, FL","f":"PANAMA CITY BEACH, FL"},6],
   [{"v":"PORT ORANGE, FL","f":"PORT ORANGE, FL"},3],
   [{"v":"Panama City, FL","f":"Panama City, FL"},1],
   [{"v":"Riverview, FL","f":"Riverview, FL"},1],
   [{"v":"SARASOTA, FL","f":"SARASOTA, FL"},23],
   [{"v":"Sarasota, ","f":"Sarasota, "},2],
   [{"v":"TRINITY, FL","f":"TRINITY, FL"},2],
   [{"v":"VALDOSTA, GA","f":"VALDOSTA, GA"},1],
   [{"v":"WARNER ROBINS, GA","f":"WARNER ROBINS, GA"},1],
   [{"v":"WINTER HAVEN, FL","f":"WINTER HAVEN, FL"},6],
   [{"v":"West Chester, OH","f":"West Chester, OH"},1]
]
const CHARTDATA_FORMINPUT = [
   {"forminput_id": 1, "question": "Participate", "formresponse": "Yes", "contactscount": 230},
   {"forminput_id": 2, "question": "Participate", "formresponse": "No", "contactscount": 124},
   {"forminput_id": 3, "question": "Business", "formresponse": "Engineering", "contactscount": 22},
   {"forminput_id": 4, "question": "Business", "formresponse": "Marketing", "contactscount": 54},
   {"forminput_id": 5, "question": "Business", "formresponse": "Mechanic", "contactscount": 76},
   {"forminput_id": 6, "question": "Business", "formresponse": "Painter", "contactscount": 98}
];
const CHARTDATA_LINKNAME = [
   {"linkname": "ABC", "contactscount": 230},
   {"linkname": "DEF", "contactscount": 340},
   {"linkname": "GHI", "contactscount": 450},
   {"linkname": "LMK", "contactscount": 560}
];
const CHARTDATA_OTHER = [
   [
      {"id":"xAxis","label":"xAxis","type":"string"},
      {"id":"id_1","label":"yAxis 1"},
      {"id":"id_2","label":"yAxis 2"},
      {"id":"id_3","label":"yAxis 3"},
      {"id":"id_4","label":"yAxis 4"},
      {"id":"id_5","label":"yAxis 5"}
   ],
   [{"v":"xAxis 1"},5,473,5,2,2],
   [{"v":"xAxis 2"},1,227,5,0,0],
   [{"v":"xAxis 3"},4,587,14,5,2],
   [{"v":"xAxis 4"},24716,4615,162,26,0],
   [{"v":"xAxis 5"},24754,4883,278,96,3],
   [{"v":"xAxis 6"},20013,1770,82,35,4],
   [{"v":"xAxis 7"},18548,767,62,20,0]
   // [
   //    {"id":"program_id","label":"program_name"},
   //    {"id":"20102","label":"Email sent to Contact"},
   //    {"id":"20103","label":"Contact opened Email"},
   //    {"id":"20104","label":"Contact opted-out of Email"},
   //    {"id":"20105","label":"Contact clicked a link in Email"},
   //    {"id":"20106","label":"Contact marked Email as spam"},
   //    {"id":"20107","label":"Email soft bounced"},
   //    {"id":"20108","label":"Email hard bounced"},
   //    {"id":"20109","label":"Email failed to process"},
   //    {"id":"20110","label":"Suppressed"}
   // ],
   // [{"v":"512","f":"9-24-21 Webinar Email 2"},0,4277,53,1264,0,10342,152,70,17618],
   // [{"v":"510","f":"9-24-21 Webinar Email 1"},27787,4563,52,1243,2,1452,57,2,1647],
   // [{"v":"513","f":"9-24-21 Webinar Email 3"},27762,6272,88,1317,1,1463,6,2,1718],
   // [{"v":"518","f":"9-24-21 Webinar Email 4"},27667,5240,55,147,1,1415,16,2,1724],
   // [{"v":"514","f":"Updated Studio Training"},506,130000,1,32,0,103,0,0,173],
   // [{"v":"500","f":"Extra"},506,130000,1,32,0,103,0,0,173]
]
const CHARTOPTIONS = {
   "chartArea": { "left": "50", "right": "50" },
   "height": "250",
   "legend": { "position": "top" },
   "width": "100%"
}
const CHARTOPTIONS_COMBOCHART = {
   "chartArea": { "left":"50", "right": "75" },
   // "colors": [ "#0f1f38","#8e7970","#f55449","#1b4b5a" ],
   "hAxis": {
      "format":"MMM dd",
      "gridlines": { "count": 5 }
   },
   "height": "250",
   "isStacked": true,
   "legend": { "position": "top" },
   "series": { 
      "0": { "type":"bars", "targetAxisIndex": 1 }
   },
   "seriesType": "areas",
   "vAxes": {
      "0": { "format":"#,###" },
      "1": { "format":"#,###" }
   },
   "width": "100%"
}
const CHARTOPTIONS_COLUMNCHART = {
   "chartArea": { "left":"50", "right":"40" },
   // "colors": [ "#0f1f38","#8e7970","#f55449","#1b4b5a" ],
   "height": "250",
   "legend": { "position": "top" },
   "width": "100%"
}
const CHARTOPTIONS_LINECHART = {
   "chartArea": { "left":"50", "right":"40" },
   // "colors": [ "#0f1f38","#8e7970","#f55449","#1b4b5a" ],
   "hAxis": {
      "format":"MMM dd",
      "gridlines": { "count":5 }
   },
   "height": "250",
   "legend": { "position": "none" },
   "width": "100%"
}
const CHARTOPTIONS_GEOCHART = {
   "height":"250",
   "width":"100%",
   "region":"US",
   "displayMode":"markers",
}
const CHARTOPTIONS_PIECHART = {
   "chartArea": { "left": "50", "width": "80%" },
   "height": "250",
   "width": "100%"
}
const CHARTOPTIONS_CALCULATEDTABLE = {
   "height":"250",
   "width":"100%",
   "class":"elevation-1 mt-2 mb-2 font-weight-light caption",
   // "class":"font-weight-bold body-2 font-italic",
   "align": "right"
}
const CHARTOPTIONS_CLICKABLETABLE = {}

function _log(msg, log) {
  if (DEBUG || log) console.log(`BtDashboardDesigner V240716.1 says => ${msg}`);
//   alert(msg);
}

function _jsonParse (value) {
   if (value) return JSON.parse(value);
   else return {};
}

function _compareItems (e1, e2) {
   return e1.text > e2.text ? 1 : -1;
}

class Dashboard {
   constructor() {
      this.name = '';
      this.audience = '';
      this.tags = [];

      this.sharedWith = {
         aids: [],
         paids : [],
         users: []
      };

      this.definition = {
         audience: '',

         //always should have 3 Filters
         filtersDefinition: [],

         datePicker: {
            type: '',
            defaultId: '',
            maxRange: 0
         },

         selectedContactsStandardFields: [],
         selectedContactsCustomFields: [],
         selectedEventsFields: [],  //'eventtimestamp','purl','event_id','servicetype_id','goal_id','program_id'
         // filterWhereClause: '',
         filterWhereClause: {},
         labels: [],

         layout: {
            flat: false,
            outlined: true,
            elevation: 24,
            raised: true,
            rows: []
         },

         preferences: new Preferences()
      }
   }
}

class Preferences {
   constructor() {
      this.chartColors = '';
      this.dashboards = false;
      this.distinct = true;
      this.seed = false;
      this.canClone = true;
      this.canGeneratePDF = false;
   }
}

class Filter {
   constructor(name) {
      this.name = name || '';
      this.reportId = '';
      this.filter_custom_field = '';
      this.preFilters = {};
      this.id = '';  //'program_ID
      this.list = [];
      this.selected = [];
      this.show = this.reportId ? true : false;
      this.multipleSelect = true;
   }
}

class Row {
   constructor(id) {
      this.id = id;
      this.cells = [];
   }
}

class Kpi {
   constructor() {
      this.id = 0;
      this.columns = 12;
      this.loading = false;
      this.reportId = '';
      this.templateName = KPI_TEMPLATE_NAMES.internal;
      this.title = 'Title 1';
      this.kpi2Title = 'Title 2';
      this.icon = 'compare';
      this.kpi1 = 0;
      this.kpi2 = 0;
      this.preFilters = {};
      this.preFilters2 = {};
      this.selectedEventsFields = [];
      this.kpi1DateRange = '';   //'today'
      this.kpi2DateRange = '';   //'yesterday'
      this.impactByFilters = []; //[0]
      this.formatNumbers = true;
      this.gaugeMaxValue = 100;
      // this.useMasterDateRange = false;
      // this.useRatio = false;
      // this.redTo = 0;
      // this.yellowTo = 0;
   }
}

class Chart {
   constructor(title) {
      this.id = 0;
      this.loading = false;
      this.reportId = '';
      this.colDIM = ''; //goal_ID (should be found in the RDL)
      this.rowDIM = ''; //date_range (should be found in the RDL)
      this.columns = 12;
      this.preFilters = {};
      this.preFilterHasPriority = false;
      this.selectedEventsFields = [];
      this.chartType = ''; // coming along with RDLs
      this.title = title;  // static text
      this.titleTemplate = '';   //"Total Goals: ##chart-total##" (with variable)
      this.chartData = [];
      this.chartOptions = CHARTOPTIONS;
      this.resultLimit = 0;
      this.labels = [];
      this.groupby_prop_field = '';
      this.groupby_custom_field = '';
      this.sum_custom_field = '';
      this.calculatedColumns = [];

      //for BtClickableTable
      this.clickableField = 'contactscount';
      this.eventFields = [];
      this.groupbyField = '';
      this.headers = [];

      //for BtPaginableTable
      // this.chartDataCount = 0;
      // this.headersCountToShow = 0;

      //for BtCalculatedTable
      this.eventsSequence = ''
      this.headersAlignment = 'start';
      this.customHeader = '';
      this.sortColumn = '';
      this.sortOrder = 'asc';
   }
}

class Setting {
   constructor(isValid) {
      this.isValid = isValid || false;
      this.message = '';
   }
}

class MyDash {
   constructor(dashboard, chartTitle, eventOptionFields) {
      _log('in MyDash: dashboard=' + JSON.stringify(dashboard))
      // this.maxChartRows = 4;  was converted to prop
      this.filters = [];
      this.filtersSetting = [];
      this.datePickerSetting = new Setting(Boolean(dashboard.definition.datePicker.type)),
      this.kpis = [];
      this.kpisSetting = [];
      this.charts = [];
      this.chartsSetting = [];
      this.maxChartColSize = [];

      dashboard.definition.filtersDefinition.forEach(filter => {
         if (filter.show) {
            // if (!filter.hasOwnProperty('multipleSelect'))
            if (!hasOwn(filter, 'multipleSelect'))
               filter.multipleSelect = true;               
            this.filters.push(filter);
            this.filtersSetting.push(new Setting(true));
         }
      });

      if (dashboard.definition.layout.rows.length > 0) {
         let chartInd = 0;
         for (let i=0; i<dashboard.definition.layout.rows.length; i++)
         {
            let cells = dashboard.definition.layout.rows[i].cells;
            if (hasOwn(cells[0], 'kpi1')) {
               cells.forEach(cell => {
                  if (hasOwn(cell, 'useMasterDateRange') && cell.useMasterDateRange)
                     cell.templateName = KPI_TEMPLATE_NAMES.master;
                  else if (cell.templateName === 'kpi-1')
                     cell.templateName = KPI_TEMPLATE_NAMES.internal;
                  this.kpis.push(cell);
                  this.kpisSetting.push(new Setting(true));
               });
            } else {
               this.charts.push([]);
               this.chartsSetting.push([]);
               this.maxChartColSize.push();
               this.maxChartColSize[chartInd] = 12 - cells.length + 1;
               cells.forEach(cell => {
                  if (hasOwn(cell, 'groupby_prop_field') && cell.groupby_prop_field) {
                     const eoField = eventOptionFields.find(f => f.value === cell.groupby_prop_field);
                     if (eoField)
                        cell.groupby_prop_field = eoField;
                  }
                  this.charts[chartInd].push(cell);
                  this.chartsSetting[chartInd].push(new Setting(true));
               });
               chartInd++;
            }
         }
      }

      if (this.charts.length === 0) {
         this.charts.push([]);
         this.chartsSetting.push([]);
         this.maxChartColSize.push(12);

         this.charts[0].push(new Chart(chartTitle));
         this.chartsSetting[0].push(new Setting());
      }
   }
}

export default {
   name: "BtDashboardDesigner",

   components: {
      BtAutocomplete,
      BtDatePicker,
      BtKpi,
      GChart,
      JsonPretty,
      BtCalculatedTable,
      BtClickableTable,
      BtPrefilters,
      BtChartOptions
   },

   props: {
      dashboardData: {
         type: Object,
         default: () => new Dashboard()
      },
      rdlsData: {
         type: Array,
         required: true
      },
      isParent: {
         type: Boolean,
         default: false
      },
      isAdmin: {
         type: Boolean,
         default: false
      },
      btnAddTitle: {
         type: String,
         default: 'CREATE DASHBOARD'
      },
      btnAddIcon: {
         type: String,
         default: "add"
      },
      btnAddColor: {
         type: String,
         default: "blue darken-1"
      },
      btnAddBlock: {
         type: Boolean,
         default: false
      },
      btnAddDisabled: {
         type: Boolean,
         default: false
      },
      btnAddShow: {
         type: Boolean,
         default: true
      },
      btnEditTitle: {
         type: String,
         default: 'EDIT DASHBOARD'
      },
      btnEditIcon: {
         type: String,
         default: "edit"
      },
      btnEditColor: {
         type: String,
         default: "gray darken-1"
      },
      btnEditBlock: {
         type: Boolean,
         default: false
      },
      btnEditDisabled: {
         type: Boolean,
         default: true
      },
      dialogAddTitle: {
         type: String,
         default: "Create your own dashboard:"
      },
      dialogEditTitle: {
         type: String,
         default: "Edit your dashboard:"
      },
      fieldFormat: {
         type: String,
         default: '1-col',
         validator: value => { return ['1-col', '2-col'].indexOf(value.toLowerCase()) !== -1 }
      },
      isAuthorized: {
         type: Boolean,
         required: true
      },
      isPowerAdmin: {
         type: Boolean,
         default: false
      },
      noPermissionMessage: {
         type: String,
         default: "Contact your admin to see how you can get this feature."
      },
      maxChartRows: {
         type: Number,
         default: 4,
         validator: value => { return (value >= 1 && value <= 9) }
      },
      debug: {
         type: Boolean,
         default: false
      },
      customFields: {
         type: Array,
         required: true
      }
   },

   data() {
      return {
         lsItemName: this.name + '_draftDashboard',
         action: '',
         isDraft: false,
         dialog: false,
         overlay: false,
         maxFilters: 3,
         maxKpis: 6,
         maxChartCols: 3,
         dashboard: new Dashboard(),
         myDash: new MyDash(new Dashboard(), this.getChartTitle(0, 0)),
         dialogTitle: '',
         audienceItems: [],
         tagItems: [
            { text: "Channel", value: "channel" },
            { text: "Event", value: "event" },
            { text: "Form", value: "form" },
            { text: "Inbound", value: "inbound" },
            { text: "Goal", value: "goal" },
            { text: "Page", value: "page" },
            { text: "Program", value: "program" },
            { text: "Outbound", value: "outbound" },
            { text: "Schedule", value: "schedule" },
            { text: "Score", value: "score" },
         ],
         eventsItems: [
            { text: "Duration", value: "duration" },
            { text: "Event ID", value: "event_id" },
            { text: "Event Option", value: "eventoption" },
            { text: "Event Timestamp", value: "eventtimestamp" },
            { text: "Form Input ID", value: "forminput_id" },
            { text: "Form Response", value: "formresponse" },
            { text: "Goal ID", value: "goal_id" },
            { text: "Inbound ID", value: "inbound_id" },
            { text: "Inbound Detail ID", value: "inbounddetail_id" },
            { text: "Link Name", value: "linkname" },
            { text: "Outbound ID", value: "outbound_id" },
            { text: "Outbound Schedule ID", value: "outboundschedule_id" },
            { text: "Program ID", value: "program_id" },
            { text: "PURL", value: "purl" },
            { text: "Score", value: "score" },
            { text: "Service Type ID", value: "servicetype_id" },
            { text: "PS List ID", value: "ps_listid" },
            { text: "PS Record ID", value: "ps_recordid" },
         ],
         standardFieldsItems: [
            { text: "Account ID", value: "accountid" },
            { text: "Address 1", value: "address1" },
            { text: "Address 2", value: "address2" },
            { text: "Anniversary", value: "anniversary" },
            { text: "Birth Date", value: "birthdate" },
            { text: "City", value: "city" },
            { text: "Company", value: "company" },
            { text: "Contact ID", value: "id" },
            { text: "Country", value: "country" },
            { text: "Delivery Point Indicator", value: "deliverypointindicator" },
            { text: "Email", value: "email" },
            { text: "Extension", value: "extension" },
            { text: "Facebook Account", value: "facebookaccount" },
            { text: "Fax", value: "fax" },
            { text: "First Name", value: "firstname" },
            { text: "Gender", value: "gender" },
            { text: "analytic_str_1", value: "analytic_str_1" },
            { text: "analytic_str_2", value: "analytic_str_2" },
            { text: "Last Name", value: "lastname" },
            { text: "linkedIn Account", value: "linkedinaccount" },
            { text: "Middle Name", value: "middlename" },
            { text: "Mobile", value: "mobile" },
            { text: "Password", value: "password" },
            { text: "Phone", value: "phone" },
            { text: "Photo URL", value: "photourl" },
            { text: "Prefix", value: "prefix" },
            { text: "PURL", value: "purl" },
            { text: "State", value: "state" },
            { text: "Suffix", value: "suffix" },
            { text: "Title", value: "title" },
            { text: "Twitter Account", value: "twitteraccount" },
            { text: "Website", value: "website" },
            { text: "Zip", value: "zip" },
            { text: "Zip Plus4", value: "zipplus4" }
         ],
         labels: '',
         currFilter: -1,
         currFilterName: '',
         dialogFilter: false,
         filterConfig: {},
         filterReportIDs: [],
         filterPreFilters: '',

         dialogDatePicker: false,
         datePickerConfig: {},
         datePickerTypes: [
            { text: "Monthly", value: "range" },
            { text: "Daily", value: "day" }
         ],
         datePickerSelectLabel: '',
         datePickerSelectItems: [],
         datePickerMaxRangeItems: [
            { text: "1 Month", value: 31 },
            { text: "2 Months", value: 62 },
            { text: "3 Months", value: 93 },
         ],

         currKpi: -1,
         dialogKpi: false,
         kpiConfig: {},
         kpiTemplateNames: KPI_TEMPLATE_NAMES,
         kpiTemplateNameItems: [],
         kpiDateRangeItems: [
            { text: "Today & Yesterday", value: "T" },
            { text: "This Week & Last Week", value: "TW" },
            { text: "This Month & Last Month", value: "TM" },
            { text: "This Quarter & Last Quarter", value: "TQ" },
            { text: "This Year & Last Year", value: "TY" }
         ],
         filterItems: [],
         kpiPreFilters: '',
         kpiPreFilters2: '',

         currChart: {},
         currChartTitle: '',
         dialogChart: false,
         chartConfig: {},
         chartReportIdHint: '',
         chartTypes: [],
         chartColorsItems: [
            { text: "Watery Blue-Greens", value: "#003b46,#07575b,#66a5ad,#c4dfe6"},
            { text: "Exotic & High-Impact", value: "#0f1f38,#8e7970,#f55449,#1b4b5a"},
            { text: "Fresh Greens", value: "#265c00,#68a225,#b3de81,#b3de99"},
            { text: "Crisp & Dramatic", value: "#505160,#68829e,#aebd38,#598234"},
            { text: "Day & Night", value: "#011a27,#063852,#f0810f,#e6df44"},
            { text: "Spicy Neutrals", value: "#af4425,#662e1c,#ebdcb2,#c9a66b"},
            { text: "Golden Afternoon", value: "#882426,#cdbea7,#323030,#c29545"}
         ],
         // chartColors: '',
         chartPreFilters: '',
         chartOptions: '',
         chartLabels: '',
         chartExcludedRows: '',

         rules: {
            required: value => !!value || 'Value is required!',
            requiredSortOrder: value => {
               if (this.chartConfig.sortColumn)
                  return !!value || 'Value is required!';
               else return true;
            },
            validJson: value => {
               try {
                  if (value) {
                     if (value.trim().indexOf('"') === 0)
                        return 'SyntaxError: Unexpected token a in JSON at position 0';
                     JSON.parse(value);
                  }
                  return true;
               } catch (error) {
                  return error.toString();
               }
            },
            validJsonArray: value => {
               try {
                  let json;
                  if (value) {
                     json = JSON.parse(value);
                     return Array.isArray(json) || 'Should be an array';
                  } else return true;
               } catch (error) {
                  return error.toString();
               }
            },
            duplicate: value => this.calculatedColumns.filter(cc => cc.name.toLowerCase() === value.toLowerCase()).length <= 1 || 
               'Value is duplicate!',
            expression: value => this.findVariables(value).length > 0 || 'Value is invalid!',
            validNumericArray: value => {
               try {
                  let arr;
                  if (value) {
                     arr = JSON.parse(value);
                     if (Array.isArray(arr)) {
                        if (!arr.length)
                           return 'Array should have one or more items!';
                        else {
                           let errMsg;
                           arr.forEach(element => {
                              if (typeof element != 'number' || !/^[0-9]+$/.test(element)) {
                                 errMsg = 'Items should be numbers only!';
                                 return;
                              }
                           });
                           return errMsg ? errMsg : true;
                        }
                     } else
                        return 'Should be an array!';
                  } else return true;
               } catch (error) {
                  return error.toString();
               }
            },
            number: value => { 
               if (value)
                  return /^\d{5,6}$/.test(value) || 'Value should be a number with 5 or 6 digits!';
               else return true;
            }
         },
         isFormFilterValid: false,
         isFormDatePickerValid: false,
         isFormKpiValid: false,
         isFormChartValid: false,
         includeFilter: false,
         includeKpi: false,
         resultLimitItems: [
            { text: "All", value: 0},
            { text: " 5", value: 5},
            { text: "10", value: 10},
            { text: "20", value: 20},
            { text: "30", value: 30},
            { text: "40", value: 40},
            { text: "50", value: 50}
         ],
         searchInput: '',
         chartSettings: {
            packages: ['corechart', 'table', 'map', 'geochart'],
            mapsApiKey: process.env.VUE_APP_GOOGLE_API_KEY
         },
         invalidSettingClass: 'error',
         validSettingClass: 'success',
         customFieldColSize: 12,
         showTooltip: false,

         isNavigator: false,
         dialogJsonViewer: false,
         prettyDashboard: {},
         prettyDashboardStringified: '',
         jsonPrettyDeep: 2,
         autoGrow: true,
         copyToClipboardHint: '',
         globalFilter: '',
         calCol: [],
         dialogCalCol: false,
         isFormCalColValid: false,
         chartCalculatedColumns: '',
         calculatedColumns: [],
         calColPositionItems: [
            { text: "At the Beginning", value: 's' },
            { text: "At the End", value: 'e' },
            { text: "Before Column...", value: "b" },
            { text: "After Column...", value: "a" },
         ],
         calColFixItems: [
            { text: "None", value: 'none' },
            { text: "Prefix (before values)", value: 'pre' },
            { text: "Postfix (after values)", value: "post" }
         ],
         headersAlignmentItems: [
            { text: "Left", value: 'start'},
            { text: "Middle", value: 'center'},
            { text: "Right", value: 'end'}
         ],
         eventOptionFields: [
            { text: 'Browser', value: 'browser'},
            { text: 'Categories', value: 'categories'},
            { text: 'Clicked URL', value: 'url'},
            { text: 'Content Type', value: 'sgContentType'},
            { text: 'Device', value: 'device'},
            { text: 'From Domain', value: 'fromDomain'},
            { text: 'Number of Attempts', value: 'attempt'},
            { text: 'Operating System', value: 'os'},
            { text: 'Reason', value: 'reason'},
            { text: 'Response', value: 'response'},
            { text: 'Sending IP Pool', value: 'sendingIpPool'},
            { text: 'Subject', value: 'subject'},
            { text: 'Status', value: 'status'},
            { text: 'To Domain', value: 'toDomain'},
            { text: 'User IP Address', value: 'ip'},
         ],
         sortOrderItems: [
            { text: "Ascending", value: "asc" },
            { text: "Descending", value: "desc" }
         ],
         chartConfigError: '',
         panel: 0,
         tab: null
      };
   },

   methods: {
      btnAddDashboardClicked() {
         if (this.isAuthorized) {
            this.action = 'add';
            this.dialogTitle = this.dialogAddTitle;

            if (localStorage.getItem(this.lsItemName) && confirm('We found a draft dashboard on your machine. Would you like to edit this dashboard?')) {
               this.dashboard = JSON.parse(localStorage.getItem(this.lsItemName));
               if (!hasOwn(this.dashboard.definition, 'preferences'))
                  this.dashboard.definition.preferences = new Preferences();
               this.isDraft = true;
            } else {
               this.dashboard = new Dashboard();
               this.isDraft = false;
            }

            this.myDash = new MyDash(this.dashboard, this.getChartTitle(0, 0), this.eventOptionFields);
            _log('in btnAddDashboardClicked(): myDash=' + JSON.stringify(this.myDash))
            this.initDialog();
         } else {
            this.showTooltip = true;
            setTimeout(() => {
               this.showTooltip = false;
            }, 5000);
         }
      },

      btnEditDashboardClicked() {
         if (this.isAuthorized) {
            this.action = 'edit';
            this.isDraft = false;
            this.dialogTitle = this.dialogEditTitle;
            _log('in btnEditDashboardClicked(): dashboardData=' + JSON.stringify(this.dashboardData))
            this.dashboard = JSON.parse(JSON.stringify(this.dashboardData));
            if (!hasOwn(this.dashboard.definition, 'selectedContactsCustomFields'))
               this.dashboard.definition.selectedContactsCustomFields = [];
            if (!hasOwn(this.dashboard.definition, 'preferences'))
               this.dashboard.definition.preferences = new Preferences();

            this.myDash = new MyDash(this.dashboard, this.getChartTitle(0, 0), this.eventOptionFields);
            _log('in btnEditDashboardClicked(): myDash=' + JSON.stringify(this.myDash))
            this.initDialog();
         } else {
            this.showTooltip = true;
            setTimeout(() => {
               this.showTooltip = false;
            }, 5000);
         }
      },

      moveLeft(propName, ind) {
         this.swipe(this.myDash[propName], ind, ind - 1);
         this.swipe(this.myDash[propName + 'Setting'], ind, ind - 1);
         this.$forceUpdate();
      },
      moveRight(propName, ind) {
         this.swipe(this.myDash[propName], ind, ind + 1);
         this.swipe(this.myDash[propName + 'Setting'], ind, ind + 1);
         this.$forceUpdate();
      },
      swipe(obj, currInd, newInd) {
         const temp = obj[currInd];
         obj[currInd] = obj[newInd];
         obj[newInd] = temp;
      },

      validate (formName) {
         this.$refs[formName].validate()
      },
      reset (formName) {
         this.$refs[formName].reset()
      },
      resetValidation (formName) {
         this.$refs[formName].resetValidation()
      },

      populateDashboard() {
         const now = new Date();
         this.dashboard.name = 'Dashboard-' + now.getMinutes() + now.getSeconds();
         this.dashboard.audience = this.audienceItems[0];
      },

      /***********************************/
      /*********** MAIN DIALOG ***********/
      /***********************************/

      initDialog() {
         // alert(JSON.stringify(this.dashboard))
         this.includeFilter = this.myDash.filters.length > 0;
         this.includeKpi = this.myDash.kpis.length > 0;
         this.audienceItems = [{ text: "Accounts", value: "accounts" }];
         if (this.isParent) this.audienceItems.push({ text: "Parents", value: "parents" });
         if (this.isAdmin) this.audienceItems.push({ text: "Admins", value: "admins" });
         if (this.audienceItems.length === 1 && this.dashboard.audience != this.audienceItems[0].value) {
            this.dashboard.audience = this.audienceItems[0].value;
            this.audienceChanged();
         }

         if ('colors' in this.myDash.charts[0][0].chartOptions) {
            // this.chartColors = this.myDash.charts[0][0].chartOptions.colors.join(',');
            this.dashboard.definition.preferences.chartColors = this.myDash.charts[0][0].chartOptions.colors.join(',');
         }
         else if ('colorAxis' in this.myDash.charts[0][0].chartOptions && 'colors' in this.myDash.charts[0][0].chartOptions.colorAxis) {
            // this.chartColors = this.myDash.charts[0][0].chartOptions.colorAxis.colors.join(',');
            this.dashboard.definition.preferences.chartColors = this.myDash.charts[0][0].chartOptions.colorAxis.colors.join(',');
         }
         else {
            // this.chartColors = this.chartColorsItems[1].value;
            this.dashboard.definition.preferences.chartColors = this.chartColorsItems[1].value;
         }

         if (hasOwn(this.dashboard.definition, 'filterWhereClause') && Object.keys(this.dashboard.definition.filterWhereClause).length > 0)
            this.globalFilter = JSON.stringify(this.dashboard.definition.filterWhereClause);
         else {
            this.globalFilter = '';
         }

         if (hasOwn(this.dashboard.definition, 'labels') && Object.keys(this.dashboard.definition.labels).length > 0)
            this.labels = JSON.stringify(this.dashboard.definition.labels);
         else this.labels = '';

         if (this.dashboard.definition.datePicker.type)
            this.datePickerTypeChanged(this.dashboard.definition.datePicker.type);
         this.overlay = true;
         this.dialog = true;
         setTimeout(() => {
            this.$refs.formMain.resetValidation();
            this.$refs.dashboardName.focus();
         }, 10);
         // _log(`in initDialog(): cf=${JSON.stringify(this.cf)}`);
         this.overlay = false;
      },

      audienceChanged() {
         this.myDash.chartsSetting.forEach((row, rInd) => {
            row.forEach((s, cInd) => {
               if (s.isValid) {
                  s.isValid = false;
                  s.message = SETTING_MESSAGE;
                  this.myDash.charts[rInd][cInd].reportId = '';
                  this.myDash.charts[rInd][cInd].chartType = '';
                  this.chartReportIdChanged('');
               }
            });
         });
      },

      includeFilterChanged(value) {
         if (value) {
            this.myDash.filters.push(new Filter('Filter #1'));
            this.myDash.filtersSetting.push(new Setting());
         } else {
            this.myDash.filters = [];
            this.myDash.filtersSetting = [];
         }
      },

      includeKpiChanged(value) {
         if (value) {
            this.myDash.kpis.push(new Kpi());
            this.myDash.kpisSetting.push(new Setting());
         } else {
            this.myDash.kpis = [];
            this.myDash.kpisSetting = [];
         }
      },

      chartColorsChanged(value) {
         this.myDash.charts.forEach((row, rowInd) => {
            row.forEach((chart, chartInd) => {
               if (chart.chartType === 'GeoChart') {
                  if (!hasOwn(chart.chartOptions, 'colorAxis'))
                     chart.chartOptions.colorAxis = {}
                  chart.chartOptions.colorAxis.colors = value.split(',');
               } else
                  chart.chartOptions.colors = value.split(',');

               this.rerenderChart(rowInd, chartInd);
            });
         });
      },

      cancelClicked() {
         this.$emit('cancel-dashboard');
         this.closeDialog();
      },

      saveClicked(asWhat) {
         _log(`in saveClicked(${asWhat}) start: dashboard=${JSON.stringify(this.dashboard)}`);
         _log(`in saveClicked(${asWhat}) start: myDash=${JSON.stringify(this.myDash)}`);
         this.dashboard.definition.audience = this.dashboard.audience;
         this.dashboard.definition.filtersDefinition = this.myDash.filters;
         this.dashboard.definition.filterWhereClause = _jsonParse(this.globalFilter);

         if (this.labels)
            this.dashboard.definition.labels = JSON.parse(this.labels);
         else
            this.dashboard.definition.labels = [];

         let rowId = 1;
         this.dashboard.definition.layout.rows = [];

         if (this.myDash.kpis.length > 0) {
            this.dashboard.definition.layout.rows.push(new Row(rowId))
            this.myDash.kpis.forEach((kpi, ind) => {
               kpi.id = rowId * 100 + ind;
               kpi.kpi1 = 0;
               kpi.kpi2 = 0;
               if (hasOwn(kpi, 'useMasterDateRange'))
                  delete kpi.useMasterDateRange;
               this.dashboard.definition.layout.rows[0].cells.push(kpi);
            });
            rowId++;
         }

         for (let i=0; i<this.myDash.charts.length; i++) {
            if (this.myDash.charts[i].length > 0) {
               this.dashboard.definition.layout.rows.push(new Row(rowId));
               this.myDash.charts[i].forEach((chart, ind) => {
                  chart.id = rowId * 100 + ind;
                  // if (chart.hasOwnProperty('resultLimit') && chart.resultLimit === 0)
                  if (hasOwn(chart, 'resultLimit') && chart.resultLimit === 0)
                     delete chart.resultLimit;
                  // if (chart.hasOwnProperty('groupby_custom_field') && chart.groupby_custom_field)
                  if (hasOwn(chart, 'groupby_custom_field') && chart.groupby_custom_field)
                     chart.groupby_custom_field = chart.groupby_custom_field.toLowerCase();

                  if (hasOwn(chart, 'groupby_prop_field') && chart.groupby_prop_field && typeof chart.groupby_prop_field != 'string')
                     chart.groupby_prop_field = chart.groupby_prop_field.value;

                  this.dashboard.definition.layout.rows[rowId-1].cells.push(chart);
               });
               rowId++;
            }
         }

         if (asWhat === 'viewJson')
            return;
         else if (asWhat === 'draft') {
            localStorage.setItem(this.lsItemName, JSON.stringify(this.dashboard));
            this.$emit('cancel-dashboard');
            this.closeDialog();
         } else {
            if (this.validateForm()) {
               this.dashboard.definition.filtersDefinition.forEach(filter => {
                  filter.list = [];
                  filter.selected = [];
                  if (!filter.filter_custom_field) delete filter.filter_custom_field;
               });

               if (this.dashboard.definition.filtersDefinition.length < this.maxFilters) {
                  const emptyFilter = { show: false };
                  for (let i=this.dashboard.definition.filtersDefinition.length; i<this.maxFilters; i++) {
                     this.dashboard.definition.filtersDefinition.push(emptyFilter);
                     this.myDash.filtersSetting.push(new Setting());
                  }
               }

               this.myDash.charts.forEach(row => {
                  row.forEach(chart => {
                     chart.chartData = [];
                  });
               });

               if (asWhat === 'preview')
                  this.$emit('preview-dashboard', this.dashboard);
               else if (this.action === 'add' || this.action === 'edit')
                  this.$emit('save-dashboard', this.dashboard);

               //TODO: temporarily while testing
               // if (this.isDraft)
               //    localStorage.removeItem(this.lsItemName);

               this.closeDialog();
            }
         }

         _log(`in saveClicked(${asWhat}) end: dashboard=${JSON.stringify(this.dashboard)}`);
      },

      validateForm() {
         let isFormValid = this.$refs.formMain.validate();

            this.myDash.filtersSetting.forEach(s => {
               if (!s.isValid) {
                  s.message = SETTING_MESSAGE;
                  isFormValid = false;
               }
            });

         if (!this.myDash.datePickerSetting.isValid) {
            this.myDash.datePickerSetting.message = SETTING_MESSAGE;
            isFormValid = false;
         }

            this.myDash.kpisSetting.forEach(s => {
               if (!s.isValid) {
                  s.message = SETTING_MESSAGE;
                  isFormValid = false;
               }
            });

         this.myDash.chartsSetting.forEach(row => {
            row.forEach(s => {
               if (!s.isValid) {
                  s.message = SETTING_MESSAGE;
                  isFormValid = false;
               }
            });
         });

         this.myDash.charts.forEach((row, i) => {
            let colTotal = 0;
            row.forEach(c => {
               colTotal += c.columns;
            });
            if (colTotal > 12) {
               this.myDash.chartsSetting[i].forEach(s => {
                  s.message += `Total columns is ${colTotal}.`;
                  isFormValid = false;
               });
            }
         });

         return isFormValid;
      },

      jsonViewerClicked() {
         this.saveClicked('viewJson');
         this.prettyDashboardStringified = JSON.stringify(this.dashboard);
         this.overlay = true;
         this.dialogJsonViewer = true;
      },

      closeDialog() {
         this.dialog = false;
      },

      /***********************************/
      /********* FILTER METHODS **********/
      /***********************************/

      addFilterClicked() {
         //alert('in addFilterClicked(): this.myDash.filters.length='+this.myDash.filters.length);
         this.myDash.filters.push(new Filter('Filter #' + (this.myDash.filters.length + 1)));
         this.myDash.filtersSetting.push(new Setting());
      },

      configureFilter(index) {
         this.overlay = true;
         this.filterConfig = JSON.parse(JSON.stringify(this.myDash.filters[index]));
         this.currFilterName = this.filterConfig.name;
         if (this.filterConfig.name.indexOf('Filter #') === 0)
            this.filterConfig.name = '';
         this.currFilter = index;

         //to get reportID items
         let isCustomfieldReportUsed = false;
         this.myDash.filters.forEach(filter => {
            if (filter.reportId === 'account-filter-customfield' && filter.name != this.filterConfig.name) {
               isCustomfieldReportUsed = true;
               return;
            }
         });
         let ids = [] ;
         if (this.dashboard.audience) {
            const audience = this.dashboard.audience.substr(0, this.dashboard.audience.length - 1) + '-';
            this.rdlsData.forEach(rdl => {
               if (rdl.name.indexOf('-filter-') > -1 
                  && (!isCustomfieldReportUsed || rdl.name != 'account-filter-customfield')
                  && rdl.name.indexOf(audience) === 0
               )
                  ids.push({ text: rdl.name, value: rdl.name });
            });
            this.filterReportIDs = ids.sort(_compareItems);
         }

         // if (this.filterConfig.hasOwnProperty('preFilters') && Object.keys(this.filterConfig.preFilters).length > 0)
         if (hasOwn(this.filterConfig, 'preFilters') && Object.keys(this.filterConfig.preFilters).length > 0)
            this.filterPreFilters = JSON.stringify(this.filterConfig.preFilters);
         else
            this.filterPreFilters = '';

         this.dialogFilter = true;
         const self = this;
         setTimeout(function() {
            self.$refs.formFilter.resetValidation();
            self.$refs.filterName.focus();
         }, 10);
      },

      deleteFilter(index) {
         if (confirm(`Are you sure you want to delete '${this.myDash.filters[index].name}'?`)) {
            this.myDash.filters.splice(index, 1);
            this.myDash.filtersSetting.splice(index, 1);
            if (this.myDash.filters.length === 0)
               this.includeFilter = false;
         }
      },

      duplicateFilter(index) {
         let filter = JSON.parse(JSON.stringify(this.myDash.filters[index]));
         filter.name += ' Copy';
         this.myDash.filters.push(filter);
         this.myDash.filtersSetting.push(this.myDash.filtersSetting[index]);
      },

      /********** FILTER DIALOG **********/

      filterReportIdChanged(value) {
         this.filterConfig.id = this.rdlsData.find(rdl => rdl.name === value).rowDim;
         this.filterConfig.filter_custom_field = '';
      },
      cancelDialogFilter() {
         this.closeDialogFilter();
      },
      saveDialogFilter() {
         if (this.$refs.formFilter.validate()) {//
            this.filterConfig.show = true;
            this.filterConfig.preFilters = _jsonParse(this.filterPreFilters);
            this.myDash.filters[this.currFilter] = JSON.parse(JSON.stringify(this.filterConfig));
            this.myDash.filtersSetting[this.currFilter] = new Setting(true);
            this.closeDialogFilter();
         }
      },
      closeDialogFilter() {
         this.currFilter = -1;
         this.overlay = false;
         this.dialogFilter = false;
      },

      /***********************************/
      /******* DATE PICKER METHODS *******/
      /***********************************/

      configureDatePicker() {
         this.datePickerConfig = JSON.parse(JSON.stringify(this.dashboard.definition.datePicker));
         this.overlay = true;
         this.dialogDatePicker = true;
         const self = this;
         setTimeout(function() {
            self.$refs.formDatePicker.resetValidation();
         }, 1);
      },
      
      /******* DATE PICKER DIALOG ********/

      datePickerTypeChanged(value) {
         if (value === 'range') {
            this.datePickerSelectItems = [
               { text: "Last 7 Days", value: "6" },
               { text: "Last 14 Days", value: "13" },
               { text: "Last 30 Days", value: "29" },
               { text: "Current Month", value: "TM" },
               { text: "Last Month", value: "LM" }
            ];
         } else {
            this.datePickerSelectItems = [
               { text: "Today", value: "T" },
               { text: "Yesterday", value: "Y" }
            ];

            this.datePickerConfig.maxRange = 0;
         }

         this.datePickerSelectLabel = `Preselected ${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
         this.datePickerConfig.defaultId = '';
         if (this.$refs['formDatePicker'])
            this.$refs.formDatePicker.resetValidation();
      },
      cancelDialogDatePicker() {
         this.closeDialogDatePicker();
      },
      saveDialogDatePicker() {
         if (this.$refs.formDatePicker.validate()) {
            this.dashboard.definition.datePicker = JSON.parse(JSON.stringify(this.datePickerConfig));
            this.myDash.datePickerSetting = new Setting(true);
            this.closeDialogDatePicker();
         }
      },
      closeDialogDatePicker() {
         // this.$refs.formDatePicker.resetValidation();
         this.dialogDatePicker = false;
         this.overlay = false;
      },

      /***********************************/
      /*********** KPI METHODS ***********/
      /***********************************/

      addKpiClicked() {
         //alert('in addKpiClicked(): this.myDash.kpis.length='+this.myDash.kpis.length);
         this.myDash.kpis.push(new Kpi());
         this.setKpiColumns();
         this.myDash.kpisSetting.push(new Setting());
      },

      configureKpi(index) {
         this.currKpi = index;
         this.kpiConfig = JSON.parse(JSON.stringify(this.myDash.kpis[index]));

         if (this.kpiConfig.title === 'Title 1') this.kpiConfig.title = '';
         if (this.kpiConfig.kpi2Title === 'Title 2') this.kpiConfig.kpi2Title = '';
         // if (!this.kpiConfig.hasOwnProperty('gaugeMaxValue')) this.kpiConfig.gaugeMaxValue = 100;
         if (!hasOwn(this.kpiConfig, 'gaugeMaxValue')) this.kpiConfig.gaugeMaxValue = 100;

         if (Object.keys(this.kpiConfig.preFilters).length > 0)
            this.kpiPreFilters = JSON.stringify(this.kpiConfig.preFilters);
         else this.kpiPreFilters = '';

         if (this.kpiConfig.preFilters2 && Object.keys(this.kpiConfig.preFilters2).length > 0)
            this.kpiPreFilters2 = JSON.stringify(this.kpiConfig.preFilters2);
         else this.kpiPreFilters2 = '';

         this.filterItems = [];
         this.myDash.filters.forEach((filter, i) => {
            if (this.myDash.filtersSetting[i].isValid)
               this.filterItems.push({ text: filter.name, value: i });
         });

         this.overlay = true;
         this.dialogKpi = true;
         const self = this;
         setTimeout(function() {
            self.$refs.formKpi.resetValidation();
            self.$refs.kpiTitle.focus();
         }, 10);
      },

      duplicateKpi(index) {
         //alert('in duplicateKpi(): index=' + index);
         let kpi = JSON.parse(JSON.stringify(this.myDash.kpis[index]));
         this.myDash.kpis.push(kpi);
         this.setKpiColumns();
         this.myDash.kpisSetting.push(this.myDash.kpisSetting[index]);
      },

      deleteKpi(index) {
         if (confirm(`Are you sure you want to delete 'Kpi #${index+1} (${this.myDash.kpis[index].title})'?`)) {
            this.myDash.kpis.splice(index, 1);
            this.setKpiColumns();
            this.myDash.kpisSetting.splice(index, 1);
            if (this.myDash.kpis.length === 0)
               this.includeKpi = false;
         }
      },

      setKpiColumns() {
         _log('in setKpiColumns()');
         const colSize = Math.floor(12 / this.myDash.kpis.length);
         // this.maxKpiColSize = 12 - 4 * (this.myDash.kpis.length - 1);
         this.myDash.kpis.forEach(kpi => {
            kpi.columns = colSize;
         });
      },

      kpiColSizeChanged() {
         this.$forceUpdate();
      },

      /************ KPI DIALOG ***********/

      // useRatioChanged(val) {
      //    if (val) this.kpiConfig.useMasterDateRange = true;
      // },

      kpiDateRangeChanged(value) {
         if (value === 'T')
            this.kpiConfig.kpi2DateRange = 'Y';
         else
            this.kpiConfig.kpi2DateRange = 'L' + value.substr(1);
      },

      // incrementNumber(val1, val2) {
      //    alert(val1+'\n'+val2);
      // },

      cancelDialogKpi() {
         this.closeDialogKpi();
      },
      saveDialogKpi() {
         if (this.$refs.formKpi.validate()) {
            this.kpiConfig.preFilters = _jsonParse(this.kpiPreFilters);
            this.kpiConfig.preFilters2 = _jsonParse(this.kpiPreFilters2);
            this.myDash.kpis[this.currKpi] = JSON.parse(JSON.stringify(this.kpiConfig));
            this.myDash.kpisSetting[this.currKpi] = new Setting(true);
            this.closeDialogKpi();
         }
      },
      closeDialogKpi() {
         // this.$refs.formKpi.resetValidation();
         this.dialogKpi = false;
         this.overlay = false;
      },
      populateKpiConfig() {
         this.kpiConfig.title = `Kpi-${this.currKpi + 1} Title-1`;
         this.kpiConfig.icon = ['compare', 'event', 'person'].splice(this.currKpi, 1).join(',');
         this.kpiConfig.kpi2Title = `Kpi-${this.currKpi + 1} Title-2`;
         let ind = this.currKpi < this.kpiReportIDs.length ? this.currKpi : 0;
         this.kpiConfig.reportId = this.kpiReportIDs[ind].value;
         // this.selectedEventsFields = [];
         ind = this.currKpi < this.kpiDateRangeItems.length ? this.currKpi : 0;
         this.kpiConfig.kpi1DateRange = this.kpiDateRangeItems[ind].value;
         // this.kpi2DateRange = '';   //'yesterday'
         // this.impactByFilters = []; //[0]
         this.kpiPreFilters = "{\"event_id\": [\"20102\"]}";
         //{"event_id": ["20102"]}
      },

      /***********************************/
      /********** CHART METHODS **********/
      /***********************************/

      addChartClicked(row, index) {
         if (index === -1) {
            this.myDash.charts.push([]);
            this.myDash.chartsSetting.push([]);
            this.myDash.maxChartColSize.push(12);
            this.myDash.charts[row].push(new Chart(this.getChartTitle(row, 0)));
         }
         else
            this.myDash.charts[row].push(new Chart(this.getChartTitle(row, index + 1)));

         this.setChartColumns(row);
         this.myDash.chartsSetting[row].push(new Setting());
         this.$forceUpdate();
      },

      configureChart(row, index) {
         this.currChart = { row: row, index: index };
         this.chartConfig = JSON.parse(JSON.stringify(this.myDash.charts[row][index]));
         this.currChartTitle = this.chartConfig.title;
         if (this.chartConfig.title === this.getChartTitle(row, index))
            this.chartConfig.title = '';

         this.chartReportIdChanged(this.chartConfig.reportId);

         // if (this.chartConfig.hasOwnProperty('preFilters') && Object.keys(this.chartConfig.preFilters).length > 0)
         if (hasOwn(this.chartConfig, 'preFilters') && Object.keys(this.chartConfig.preFilters).length > 0)
            this.chartPreFilters = JSON.stringify(this.chartConfig.preFilters);
         else {
            this.chartPreFilters = '';
         }

         // if (this.chartConfig.chartOptions.hasOwnProperty('colors'))
         if (hasOwn(this.chartConfig.chartOptions, 'colors'))
            delete this.chartConfig.chartOptions.colors;
         
         if (Object.keys(this.chartConfig.chartOptions).length > 0) {
            this.chartOptions = JSON.stringify(this.chartConfig.chartOptions);
            // if (this.chartConfig.chartType === 'PieChart' && this.chartConfig.chartOptions.hasOwnProperty('pieHole'))
            if (this.chartConfig.chartType === 'PieChart' && hasOwn(this.chartConfig.chartOptions, 'pieHole'))
               this.chartConfig.chartType = 'DonutChart'
         } else this.chartOptions = '';
         
         // if (!this.chartConfig.hasOwnProperty('resultLimit'))
         if (!hasOwn(this.chartConfig, 'resultLimit'))
            this.chartConfig.resultLimit = 0;
         
         // if (this.chartConfig.hasOwnProperty('labels') && Object.keys(this.chartConfig.labels).length > 0)
         if (hasOwn(this.chartConfig, 'labels') && Object.keys(this.chartConfig.labels).length > 0)
            this.chartLabels = JSON.stringify(this.chartConfig.labels);
         else this.chartLabels = '';
         
         // if (this.chartConfig.hasOwnProperty('excludedRows') && Object.keys(this.chartConfig.excludedRows).length > 0)
         if (hasOwn(this.chartConfig, 'excludedRows') && Object.keys(this.chartConfig.excludedRows).length > 0)
            this.chartExcludedRows = JSON.stringify(this.chartConfig.excludedRows);
         else this.chartExcludedRows = '';

         if (this.chartConfig.calculatedColumns && this.chartConfig.calculatedColumns.length > 0)
            this.chartCalculatedColumns = JSON.stringify(this.chartConfig.calculatedColumns);
         else
            this.chartCalculatedColumns = '';

         this.overlay = true;
         this.dialogChart = true;
         const self = this;
         setTimeout(function() {
            self.$refs.formChart.resetValidation();
            self.$refs.chartName.focus();
         }, 10);
      },

      duplicateChart(row, index) {
         //alert('in duplicateChart(): row=' + row + ', index=' + index);
         let cell = JSON.parse(JSON.stringify(this.myDash.charts[row][index]));
         this.myDash.charts[row].push(cell);
         this.setChartColumns(row);
         this.myDash.chartsSetting[row].push(this.myDash.chartsSetting[row][index]);
      },

      deleteChart(row, index) {
         // alert('in deleteChart(): row=' + row + ', index=' + index);
         if (confirm(`Are you sure you want to delete '${this.myDash.charts[row][index].title}'?`)) {
            this.myDash.charts[row].splice(index, 1);
            this.setChartColumns(row);
            this.myDash.chartsSetting[row].splice(index, 1);

            let lastRow;
            if (this.myDash.charts[row].length === 0) {
               this.myDash.charts.splice(row, 1);
               this.myDash.chartsSetting.splice(row, 1);
               this.myDash.maxChartColSize.splice(row, 1);
               lastRow = this.myDash.charts.length;
            } else
               lastRow = row + 1;

            for (let i=row; i<lastRow; i++) {
               for (let j=0; j<this.myDash.charts[i].length; j++) {
                  if (!this.myDash.chartsSetting[i][j].isValid)
                     this.myDash.charts[i][j].title = this.getChartTitle(i, j);
               }
            }
         }
      },
      getChartTitle(row, index) {
         return `Row #${row + 1}, Cell #${index + 1}`;
      },
      setChartColumns(row) {
         _log('in setChartColumns(): row='+row);
         const colSize = 12 / this.myDash.charts[row].length;
         this.myDash.maxChartColSize[row] = 12 - this.myDash.charts[row].length + 1;
         this.myDash.charts[row].forEach((chart,ind) => {
            chart.columns = colSize;
            this.rerenderChart(row, ind);
         });
      },
      rerenderChart(row, ind) {
         _log('in rerenderChart(): row='+row+', ind='+ind);
         this.myDash.charts[row][ind].chartOptions.width = '50%'
         const chartData = JSON.stringify(this.myDash.charts[row][ind].chartData);
         this.myDash.charts[row][ind].chartData = [];
         const self=this;
         // setTimeout(function () {
            self.myDash.charts[row][ind].chartOptions.width = '100%'
            self.myDash.charts[row][ind].chartData = JSON.parse(chartData);
            self.$forceUpdate();
         // }, 1);
      },
      shouldShowDownArrow(i) {
         // let show = i != this.myDash.maxChartRows && this.myDash.charts[i-1].length > 1;
         let show = i != this.maxChartRows && this.myDash.charts[i-1].length > 1;
         if (show)
            show = i >= this.myDash.charts.length || this.myDash.charts[i].length < this.maxChartCols;
         return show;
      },
      moveChartLeft(row, ind) {
         this.swipe(this.myDash.charts[row], ind, ind - 1);
         this.swipe(this.myDash.chartsSetting[row], ind, ind - 1);
         this.$forceUpdate();
      },
      moveChartRight(row, ind) {
         this.swipe(this.myDash.charts[row], ind, ind + 1);
         this.swipe(this.myDash.chartsSetting[row], ind, ind + 1);
         this.$forceUpdate();
      },
      moveChartDown(row, ind) {
         this.swipeCharts(row, ind, row + 1);
      },
      moveChartUp(row, ind){
         this.swipeCharts(row, ind, row - 1);
      },

      swipeCharts(currRow, currInd, newRow) {
         let newInd;
         if (newRow < this.myDash.charts.length)
            newInd = this.myDash.charts[newRow].length - 1;
         else
            newInd = -1;

         this.addChartClicked(newRow, newInd);
         newInd++;
         this.myDash.charts[newRow][newInd] = this.myDash.charts[currRow].splice(currInd, 1)[0];
         this.myDash.chartsSetting[newRow][newInd] = this.myDash.chartsSetting[currRow].splice(currInd, 1)[0];
         this.setChartColumns(currRow);
         this.setChartColumns(newRow);
         this.$forceUpdate();
      },

      /************ CHART DIALOG ***********/

      chartReportIdChanged(value) {
         // alert('value=' + value + ', type=' + this.chartConfig.chartType);
         this.chartTypes = [];
         this.chartConfigError = '';

         if (value) {
            const rdl = this.rdlsData.find(rdl => rdl.name === value);
            if (rdl) {
               this.chartConfig.colDIM = rdl.colDim;
               this.chartConfig.rowDIM = rdl.rowDim;
               this.chartReportIdHint = `Report ID (Col Dim: ${this.chartConfig.colDIM || 'none'}, Row Dim: ${this.chartConfig.rowDIM || 'none'})`;

               rdl.charts.forEach(chart => {
                  this.chartTypes.push({ text: chart, value: chart });
                  if (chart === 'PieChart')
                     this.chartTypes.push({ text: 'DonutChart', value: 'DonutChart' });
               });

               if (rdl.charts.length === 1) {
                  this.chartConfig.chartType = rdl.charts[0];
                  this.chartTypeChanged();
               }
            } else
               this.chartConfigError = `'${value}' RDL could not be found!`;
         
            if (this.chartTypes.find(item => item.value === this.chartConfig.chartType) == undefined)
               this.chartConfig.chartType = '';

            // alert('chartConfig.chartData1=' + JSON.stringify(this.chartConfig.chartData));

            if (!this.chartConfig.chartData.length || value != this.myDash.charts[this.currChart.row][this.currChart.index].reportId)
               this.chartConfig.chartData = this.getChartData(value);

            // alert('chartConfig.chartData2=' + JSON.stringify(this.chartConfig.chartData));

            if (value === 'account-groupby-customfield-sum')
               this.customFieldColSize = 6;
            else
               this.customFieldColSize = 12;
         } else {
            this.chartConfig.colDIM = '';
            this.chartConfig.rowDIM = '';
            this.chartReportIdHint = 'Report ID';
         }
      },
      getChartData(reportId) {
         const reportIdParts = reportId.split('-');
         const yAxis = reportIdParts[reportIdParts.length - 2];
         const xAxis = reportIdParts[reportIdParts.length - 1];
         let chartData;
         if (xAxis === 'count') {
            if (yAxis === 'forminput')
               chartData = JSON.parse(JSON.stringify(CHARTDATA_FORMINPUT));
            else if (yAxis === 'linkname')
               chartData = JSON.parse(JSON.stringify(CHARTDATA_LINKNAME));
            else
               chartData = JSON.parse(JSON.stringify(CHARTDATA_COUNT).replace(/\?/g, yAxis));
         } else if (xAxis === 'city') {
            chartData = JSON.parse(JSON.stringify(CHARTDATA_CITY).replace(/\?/g, yAxis));
         } else {
            chartData = JSON.parse(JSON.stringify(CHARTDATA_OTHER).replace(/xAxis/g, xAxis).replace(/yAxis/g, yAxis));
         }
         // alert(JSON.stringify(chartData))
         return chartData;
      },
      chartTypeChanged() {
         if (this.chartConfig.chartType === 'GeoChart')
            this.chartOptions = JSON.stringify(CHARTOPTIONS_GEOCHART);
         else if (this.chartConfig.chartType === 'CalculatedTable')
            this.chartOptions = JSON.stringify(CHARTOPTIONS_CALCULATEDTABLE);
         else if (this.chartConfig.chartType === 'ClickableTable')
            this.chartOptions = JSON.stringify(CHARTOPTIONS_CLICKABLETABLE);
         else if (this.chartConfig.chartType === 'ComboChart')
            this.chartOptions = JSON.stringify(CHARTOPTIONS_COMBOCHART);
         else if (this.chartConfig.chartType === 'ColumnChart')
            this.chartOptions = JSON.stringify(CHARTOPTIONS_COLUMNCHART);
         else if (this.chartConfig.chartType === 'LineChart')
            this.chartOptions = JSON.stringify(CHARTOPTIONS_LINECHART);
         else if (this.chartConfig.chartType === 'PieChart')
            this.chartOptions = JSON.stringify(CHARTOPTIONS_PIECHART);
         else if (this.chartConfig.chartType === 'DonutChart') {
            let donutOptions = JSON.parse(JSON.stringify(CHARTOPTIONS_PIECHART));
            donutOptions.pieHole = 0.3;
            this.chartOptions = JSON.stringify(donutOptions);
         } else {
            // let chartOptions = _jsonParse(this.chartOptions);
            // const hasPieHole = chartOptions.hasOwnProperty('pieHole');
            // if (this.chartConfig.chartType === 'DonutChart' && !hasPieHole)
            //    chartOptions.pieHole = 0.3;
            // else if (hasPieHole)
            //    delete chartOptions.pieHole;
            // this.chartOptions = JSON.stringify(chartOptions);
            this.chartOptions = JSON.stringify(CHARTOPTIONS);
         }
      },

      editCalculatedColumns() {
         this.calculatedColumns = this.chartCalculatedColumns ? JSON.parse(this.chartCalculatedColumns) : [];

         this.calculatedColumns.forEach(calCol => {
            const fixParts = calCol.fix.split('::');
            calCol.fix = {
               menu: false,
               list: fixParts[0],
               text: fixParts[1] || this.calColFixItems.find(f => f.value === fixParts[0]).text,
            };

            const positionParts = calCol.position.split('::');
            calCol.position = {
               menu: false,
               list: positionParts[0],
               text: positionParts[1] || this.calColPositionItems.find(p => p.value === positionParts[0]).text,
            };

            this.setCalColFixHintAndPlaceholder(calCol.fix);
            this.setCalColPositionHintAndPlaceholder(calCol.position);
         });

         this.addCalCol();
         this.$nextTick(() => this.dialogCalCol = true);
      },
      clearCalculatedColumns() {
         this.chartCalculatedColumns = '';
      },

      cancelDialogChart() {
         this.closeDialogChart();
      },
      saveDialogChart() {
         if (this.$refs.formChart.validate()) {
            this.chartConfig.preFilters = _jsonParse(this.chartPreFilters);
            this.chartConfig.chartOptions = _jsonParse(this.chartOptions);
            this.chartConfig.labels = _jsonParse(this.chartLabels);
            this.chartConfig.excludedRows = _jsonParse(this.chartExcludedRows);

            if (this.chartConfig.chartType === 'GeoChart') {
               // if (!this.chartConfig.chartOptions.hasOwnProperty('colorAxis'))
               if (!hasOwn(this.chartConfig.chartOptions, 'colorAxis')) {
                  this.chartConfig.chartOptions.colorAxis = {};
               }
               // this.chartConfig.chartOptions.colorAxis.colors = this.chartColors.split(',');
               this.chartConfig.chartOptions.colorAxis.colors = this.dashboard.definition.preferences.chartColors.split(',');
            } else if (!['CalculatedTable','ClickableTable'].includes(this.chartConfig.chartType)) {
               // this.chartConfig.chartOptions.colors = this.chartColors.split(',');
               this.chartConfig.chartOptions.colors = this.dashboard.definition.preferences.chartColors.split(',');
               if (this.chartConfig.chartType === 'DonutChart')
                  this.chartConfig.chartType = 'PieChart'
            }

            /* V240610 - start */
            // if (this.chartConfig.chartType === 'CalculatedTable' && this.chartCalculatedColumns) {
            //    this.chartConfig.calculatedColumns = JSON.parse(this.chartCalculatedColumns);               
            // } else {
            //    if (hasOwn(this.chartConfig, 'calculatedColumns'))
            //       delete this.chartConfig.calculatedColumns;
            //    if (hasOwn(this.chartConfig, 'sortColumn')) {
            //       delete this.chartConfig.sortColumn;
            //       delete this.chartConfig.sortOrder;
            //    }
            // }

            if (this.chartConfig.chartType === 'CalculatedTable') {
                  this.chartConfig.calculatedColumns = this.chartCalculatedColumns ? JSON.parse(this.chartCalculatedColumns) : [];               
            } else {
               if (hasOwn(this.chartConfig, 'calculatedColumns'))
                  delete this.chartConfig.calculatedColumns;
               if (hasOwn(this.chartConfig, 'sortColumn')) {
                  delete this.chartConfig.sortColumn;
                  delete this.chartConfig.sortOrder;
               }
            }
            /* V240610 - end */

            if (this.chartConfig.chartType === 'ClickableTable') {
               this.chartConfig.clickableField = 'contactscount';
               if (this.chartConfig.reportId === 'account-groupby-forminput-count') {
                  this.chartConfig.eventFields = ['forminput_id','formresponse'];
                  this.chartConfig.groupbyField = 'question';
                  this.chartConfig.headers = [
                     { text: 'Question', value: ''},
                     { text: 'Answer', value: 'formresponse'},
                     { text: 'Count', value: 'contactscount'}
                  ];                  
               } else {
                  this.chartConfig.eventFields = ['linkname'];
                  this.chartConfig.groupbyField = 'undefined';
                  this.chartConfig.headers = [
                     { text: 'Link', value: 'linkname'},
                     { text: 'Contacts', value: 'contactscount'}
                  ];                  
               }
            } else {
               delete this.chartConfig.clickableField;
               delete this.chartConfig.eventFields;
               delete this.chartConfig.groupbyField;
               delete this.chartConfig.headers;
            }

            this.myDash.charts[this.currChart.row][this.currChart.index] = JSON.parse(JSON.stringify(this.chartConfig));
            this.myDash.chartsSetting[this.currChart.row][this.currChart.index] = new Setting(true);

            this.closeDialogChart();
            // alert(JSON.stringify(this.myDash.charts[this.currChart.row][this.currChart.index]));
         }
      },
      closeDialogChart() {
         // this.$refs.formChart.resetValidation();
         this.dialogChart = false;
         this.overlay = false;
      },

      /************ CALCULATED COLUMNS DIALOG ***********/
      addCalCol() {
         const len = this.calculatedColumns.length;
         if (len === 0 || (this.calculatedColumns[len - 1].name && len < 5)) {
            const calCol = {
               name: '',
               expression: '',
               fix: {
                  menu: false,
                  list: '',
                  text: ''
               },
               position: {
                  menu: false,
                  list: '',
                  text: ''
               }
            };

            this.setCalColFixHintAndPlaceholder(calCol.fix);
            this.setCalColPositionHintAndPlaceholder(calCol.position);
            this.calculatedColumns.push(calCol);
         }

         // alert(JSON.stringify(this.calculatedColumns));
      },
      removeCalCol(ind) {
         if (confirm('Are you sure?')) {
            this.calculatedColumns.splice(ind, 1);
            this.addCalCol()
         }
      },

      calColFixMenuChanged(listItem, item) {
         // alert('in calColFixMenuChanged(): listItem='+JSON.stringify(listItem)+'\nitem='+JSON.stringify(item));
         item.fix.menu = false;
         item.fix.list = listItem.value;
         if (listItem.value === 'none')
            item.fix.text = listItem.text;
         else if (item.fix.text === this.calColFixItems.find(cc => cc.value === 'none').text)
            item.fix.text = '';

         this.setCalColFixHintAndPlaceholder(item.fix);
         this.$refs[`fix_${item.name}`][0].focus();
      },
      setCalColFixHintAndPlaceholder(fix) {
         // alert('in setCalColFixHintAndPlaceholder(): fix=' + JSON.stringify(fix));
         if (fix.list === 'none')
            fix.hint = 'No pre/postfix';
         else if (fix.list === 'pre')
            fix.hint = 'Prefix';
         else if (fix.list === 'post')
            fix.hint = 'Postfix';
         else
            fix.hint = 'Pre/Postfix';

         if (fix.list === '')
            fix.placeholder = 'select an option';
         else if (fix.list === 'pre')
            fix.placeholder = 'specify the prefix';
         else if (fix.list === 'post')
            fix.placeholder = 'specify the postfix';
         else
            fix.placeholder = '';
      },
      findVariables(expression) {
         const startMark = "{{";
         const endMark = "}}";
         const regEx = /{{[^{]+}}/g;
         const matches = expression.match(regEx) || [];
         // console.log('matches=' + JSON.stringify(matches));
         const vars = [];

         matches.forEach(match => {
            const key = match.replace(startMark, '').replace(endMark, '');
            // console.log('match=' + match + ', key=' + key);
            if (key)
               vars.push(key);
         });

         // console.log('vars=' + JSON.stringify(vars));
         return vars;
      },

      calColPositionMenuChanged(listItem, item) {
         item.position = {
            menu: false,
            list: listItem.value,
            text: ['b','a'].includes(listItem.value) ? '' : listItem.text
         };

         this.setCalColPositionHintAndPlaceholder(item.position);
         this.$refs[item.name][0].focus();
         // alert(JSON.stringify(item));
      },
      setCalColPositionHintAndPlaceholder(position) {
         position.hint = 'Position';
         if (position.list === 'b')
            position.hint += ': Before the above column';
         else if (position.list === 'a')
            position.hint += ': After the above column';

         if (position.list === '')
            position.placeholder = 'select an option';
         else if (position.list === 'b')
            position.placeholder = 'specify the before column name';
         else if (position.list === 'a')
            position.placeholder = 'specify the after column name';
         else
            position.placeholder = '';

         // alert(JSON.stringify(position));
      },
      // resetCalCol() {
      //    if (confirm('Are you sure?')) {
      //       this.calculatedColumns = [];
      //       this.resetDisabled = true;
      //       this.addCalCol();
      //    }
      // },
      cancelCalCol() {
         this.$refs.formCalCol.resetValidation();
         this.dialogCalCol = false;
      },
      saveCalCol() {
         if (this.$refs.formCalCol.validate()) {
            if (!this.calculatedColumns[this.calculatedColumns.length - 1].name)
               this.calculatedColumns.pop();

            // this.chartCalculatedColumns = JSON.stringify(this.calculatedColumns);
            this.cancelCalCol();
            this.calculatedColumns.forEach(calCol => {
               calCol.fix = calCol.fix.list + '::' + (['pre','post'].includes(calCol.fix.list) ? calCol.fix.text : '');
               calCol.position = calCol.position.list + '::' + (['b','a'].includes(calCol.position.list) ? calCol.position.text : '');
            });

            this.chartCalculatedColumns = JSON.stringify(this.calculatedColumns);
         }
      },

      /************ JSON VIEWER DIALOG ***********/
      // jsonItemClicked(key, val){
      jsonItemClicked(key){
         const el = this.$refs.jsonPretty.$el.querySelector("textarea");
         el.setSelectionRange(0,0);
         let sInd = 0;
         let lastKeyLen = 0;
         key.split('.').forEach(k => {
            if (k) {
               const keyParts = k.split('[');
               sInd = this.prettyDashboardStringified.indexOf(`"${keyParts[0]}":`, sInd + lastKeyLen + 1);
               lastKeyLen = keyParts[0].length;
               // alert('k='+k+'\nsInd='+sInd+'\nlastKeyLen='+lastKeyLen);
            }
         });
         // let cursorPos = el.selectionEnd;
         const eInd = sInd + lastKeyLen;
         // alert('sInd='+sInd+', eInd='+eInd);
         setTimeout(() => {
            // el.setSelectionRange(el.selectionEnd, el.selectionEnd+20);
            el.select(sInd, eInd);
            el.setSelectionRange(0,0);
            el.setSelectionRange(sInd + 1, eInd + 1);
            // el.selectionStart = sInd;
            // el.selectionEnd = eInd;
         }, 100);
         // this.$nextTick(() => el.setSelectionRange(el.selectionEnd, el.selectionEnd+10));
      },
      async copyToClipboard() {
         if (this.isNavigator) {
            await navigator.clipboard.writeText(this.prettyDashboardStringified);
            this.copyToClipboardHint = 'JSON content was copied to clipboard at ' + new Date().toString().split(' ')[4];
         }
      },
      saveDialogJsonViewer() {
         this.dashboard = this.prettyDashboard;
         this.myDash = new MyDash(this.dashboard, this.getChartTitle(0, 0), this.eventOptionFields);
         _log('in saveDialogJsonViewer(): myDash=' + JSON.stringify(this.myDash))
         this.initDialog();
         this.closeDialogJsonViewer();
      },
      cancelDialogJsonViewer() {
         this.closeDialogJsonViewer();
      },
      closeDialogJsonViewer() {
         this.dialogJsonViewer = false;
         this.overlay = false;
      }
   },

   computed: {
      filterReportIdHint() {
         if (this.filterConfig.id) return `Report ID (id: ${this.filterConfig.id})`;
         else return 'Report ID';
      },

      // filterReportIDs() {
      //    alert('in filterReportIDs()');
      //    let isCustomfieldReportUsed = false;
      //    this.myDash.filters.forEach(filter => {
      //       if (filter.reportId === 'account-filter-customfield' && filter.name != this.filterConfig.name) {
      //          isCustomfieldReportUsed = true;
      //          return;
      //       }
      //    });
      //    let ids = [] ;
      //    this.rdlsData.forEach(rdl => {
      //       if (rdl.name.indexOf('-filter-') > -1 && 
      //          (!isCustomfieldReportUsed || rdl.name != 'account-filter-customfield'))
      //          ids.push({ text: rdl.name, value: rdl.name });
      //    });
      //    return ids;
      // },

      datePickerPlaceholder() {
         let placeholder = 'Date Picker';

         if (this.datePickerSelectItems.length > 0 && this.dashboard.definition.datePicker.defaultId) {
            const myItem = this.datePickerSelectItems.find(item => item.value === this.dashboard.definition.datePicker.defaultId)
            if (myItem != undefined)
               placeholder = myItem.text;
         }
         
         return placeholder;
      },

      kpiReportIDs() {
         return [
            { text: "account-count-contacts-all", value: "account-count-contacts-all" },
            { text: "account-count-contacts", value: "account-count-contacts" },
            { text: "account-count-eventtransaction", value: "account-count-eventtransaction" },
            { text: "account-sum-scores", value: "account-sum-scores" }
         ].sort(_compareItems);
      },

      chartReportIDs() {
         let ids = [] ;
         if (this.dashboard.audience) {
            const audience = this.dashboard.audience.substr(0, this.dashboard.audience.length - 1) + '-';
            this.rdlsData.forEach(rdl => {
               if (rdl.name.indexOf(audience) === 0 && rdl.name.includes('-groupby-')) //rdl.format === '2da'
                  ids.push({ text: rdl.name, value: rdl.name });
            });
         }
         return ids.sort(_compareItems);
      },

      maxKpiColSize() {
         let colSize = 12;
         if (this.myDash && this.myDash.kpis && this.myDash.kpis.length > 1)
            colSize = 12 - 2 * (this.myDash.kpis.length - 1);
         // if (this.myDash && this.myDash.kpis && this.myDash.kpis.length > 1)
         // {
         //    let tot = 0;
         //    this.myDash.kpis.forEach(kpi => {
         //       tot += kpi.columns;
         //    });

         //    colSize = (12/this.myDash.kpis.length) + (12 - tot);
         // }
         return colSize;
      },
      kpiGaugeColors() {
         // if (this.chartColors) {
         if (this.dashboard.definition.preferences.chartColors) {
            // const colors = this.chartColors.split(',');
            const colors = this.dashboard.definition.preferences.chartColors.split(',');
            return [colors[0], colors[1], colors[2]];
         } else return [];
      }
   },

   watch: {
      prettyDashboardStringified(val) {
         this.prettyDashboard = _jsonParse(val);
      }
   },

   mounted() {
      DEBUG = this.debug;
      this.kpiTemplateNameItems = [
         { text: "Internal Date Range", value: this.kpiTemplateNames.internal },
         { text: "Master Date Range", value: this.kpiTemplateNames.master },
         { text: "Ratio with Master Date Range", value: this.kpiTemplateNames.ratio }
      ];
      this.isNavigator = navigator.clipboard;
   }
};
</script>

<style scoped>
.v-select.v-input--dense .v-chip {
    margin: 0 2px 4px 4px;
}
.switch {
   font-size: 0.875rem;
   font-weight: 400;
   line-height: 1.375rem;
   letter-spacing: 0.0071428571em;
}
.v-expansion-panel-header, .v-expansion-panel-header--active {
   /* padding: 0 16px; */
   min-height: 40px !important;
}
::v-deep .v-expansion-panel-content__wrap {
    padding: 8px 8px 8px;
}
</style>
